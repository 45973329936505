import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {
    KeystrokeData,
    KeystrokeDetailsData,
} from 'src/models/captures/keystrokeData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getKeystrokeDetails,
    getKeystrokes,
    selectFilters,
    selectKeystrokes,
    selectKeystrokesStatus,
    selectKeystrokeDetails,
    selectKeystrokeDetailsStatus,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { HighlightText } from 'src/components/highlight-text/HighlightText';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { KeystrokeDetails } from './KeystrokeDetails';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';


export function KeystrokeAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const keystrokesStatus = useSelector(selectKeystrokesStatus);
    const keystrokesData = useSelector(selectKeystrokes);
    const keystrokeDetailsStatus = useSelector(selectKeystrokeDetailsStatus);
    const keystrokeDetailsData = useSelector(selectKeystrokeDetails);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFilter, setCurrentFilter] = useState(filters);
    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);
    const createKeystrokeData = (keystroke: KeystrokeData): void =>
    {
        if (datas === undefined)
        {
            setDatas([keystroke.id]);
            return;
        }
        if (!datas.includes(keystroke.id))
        {
            setDatas([...datas, keystroke.id]);
        }
    };
    const handleOpen = (keystroke: KeystrokeData): void =>
    {
        createKeystrokeData(keystroke);

        dispatch(getKeystrokeDetails(keystroke.id));

        if (!keystroke.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: keystroke.id,
                dataType: DataType.KEYWORDS,
            }));
        }

        setOpen(true);
    };
    function onPageChange(pageNumber: number): void
    {
        if (keystrokesData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(keystrokesData?.contents[
                    keystrokesData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) &&
                    lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getKeystrokes({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ?
                currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: KeystrokeData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.KEYWORDS}
            onChange={() =>
            {
                onPageChange(keystrokesData?.currentPage ?? 1);
            }}

        />
    );
    const getContentColumn = (data: KeystrokeData): React.ReactNode => (
        <p title={data.value}>
            {(data.value?.length > 250) ? (
                <HighlightText
                    textToHighlight={data.value.substring(0, 250) + '...'}
                    highlightWords={data?.alertWords || ['']}
                />
            )
                : (
                    <HighlightText
                        textToHighlight={data?.value || ''}
                        highlightWords={data?.alertWords || ['']}
                    />
                )}
        </p>
    );
    const openDetailColumn = (data: KeystrokeData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: KeystrokeData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: KeystrokeData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: KeystrokeData) =>
                new Date(data.capturedDate)?.toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: KeystrokeData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: KeystrokeData) => data.device,
            sortByType: 'device',
            /* in the future fix to go by alias*/
        },
        {
            label: t('data-type.table.content'),
            size: 4,
            value: (data: KeystrokeData) => data.value,
            sortByType: 'value',
            format: (data: KeystrokeData) => getContentColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: KeystrokeData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.details.program-name'),
            size: 2,
            value: (data: KeystrokeData) => data.appOnFocus,
            sortByType: 'appOnFocus',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: KeystrokeData) => openDetailColumn(data),
        },
    ];
    const detailItems = [
        {
            label: t('data-type.details.received-date'),
            text: (data: KeystrokeDetailsData) =>
                timeZoneFormat(new Date(data.receivedDate), USER_TIME_ZONE),
        },
        {
            label: t('data-type.details.captured-date'),
            text: (data: KeystrokeDetailsData) =>
                timeZoneFormat(new Date(data.capturedDate), USER_TIME_ZONE),
        },
        {
            label: t('data-type.details.device-alias'),
            text: (data: KeystrokeDetailsData) => data?.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: KeystrokeDetailsData) => data?.username,
        },
        {
            label: t('data-type.table.platform'),
            text: (data: KeystrokeDetailsData) => data?.platform,
        },
        {
            label: t('data-type.details.alert-words'),
            text: (data: KeystrokeDetailsData) =>
                data?.alertWords?.join(', '),
        },
    ];

    useEffect(() =>
    {
        if (keystrokesData)
        {
            setLastPage(Math.floor(
                keystrokesData.numberOfEntries / entriesPerPage
            ));
            pdfExportSetter({
                type: DataType.KEYWORDS,
                recordIds: keystrokesData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(keystrokesData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.KEYWORDS)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keystrokesData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<KeystrokeDetailsData>
                title={t('data-type.details.keystrokes-title')}
                open={open}
                data={keystrokeDetailsData}
                detailItems={detailItems}
                onClose={() => setOpen(false)}
                isLoading={keystrokeDetailsStatus === RequestStatus.InProgress}
                comments={{
                    contentId: keystrokeDetailsData?.id,
                    type: CommentType.KEYWORDS,
                }}
            >
                <KeystrokeDetails {...(keystrokeDetailsData as KeystrokeDetailsData)} />
            </DataDetailsModal>
            {useDesktopScreen() ? (
                <DataTable<KeystrokeData>
                    isLoading={keystrokesStatus === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    entriesPerPage={entriesPerPage}

                    datas={datas}
                    {...keystrokesData}
                />
            ) : (
                <DataTableMobile
                    isLoading={keystrokesStatus === RequestStatus.InProgress}
                    contents={keystrokesData?.contents || []}
                    setOpenModal={handleOpen}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    entriesPerPage={entriesPerPage}
                    numberOfEntries={keystrokesData?.numberOfEntries}
                    currentPage={keystrokesData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
