export enum DataType {
  KEYWORDS = 'Keywords',
  WEBSITES = 'Websites',
  SCREENSHOTS = 'Screenshots',
  SCREENSHOTS_META = 'Screenshots Meta',
  PROGRAMS = 'Programs',
  PROCESSES = 'Processes',
  EMAILS = 'Emails',
  ALERTS = 'ALERTS',
  SCREEN_CAPTURES = 'Screen Captures',
  MMS = 'MMS',
  SMS = 'SMS',
  CALLS = 'Calls',
  PHOTOS = 'Photos',
  GEOLOCATION = 'Geolocation',
  PRINTED_DOCS = 'Printed Docs',
  LOGON_EVENTS = 'Logon Events',
  EXTERNAL_MEDIA = 'External Media',
  FILES_TRACKING = 'Files Tracking',
  WEBSITES_SEARCH = 'Websites Search',
  SMS_EXPORT = 'sms',
  NON_REPORTER = 'Non Reporter',
  ACTIVE_REPORTER = 'Active Reporter',
  AGENCY_OFFICER='Agency Officers',
  AGENCY_CLIENT='Agency Clients',
  AGENCY_DEVICES='Agency Devices',
  CLIENT_DEVICES='Client Devices',
  DEVICE_PERMISSIONS = 'Device Permissions',
  DEVICE_INFORMATION = 'Device Information',
  OFFICER_CLIENTS = 'Officer Clients',
  OFFICER_DEVICES = 'Officer Devices',
}
export enum DataTypeConfig {
    GEOLOCATION = 'geolocation',
    WEBSITES = 'websites',
    SEARCHES = 'searches',
    SEARCHES_WEBSITES = 'searches / websites',
}

export function dataTypeStringKey(dataType: DataType): string
{
    switch (dataType)
    {
    case DataType.KEYWORDS:
        return 'common.data-type.keywords';
    case DataType.WEBSITES:
        return 'common.data-type.websites';
    case DataType.SCREENSHOTS:
        return 'common.data-type.screenshots';
    case DataType.PROGRAMS:
        return 'common.data-type.programs';
    case DataType.EMAILS:
        return 'common.data-type.emails';
    case DataType.SCREEN_CAPTURES:
        return 'common.data-type.screen-captures';
    case DataType.SMS:
        return 'common.data-type.sms';
    case DataType.MMS:
        return 'common.data-type.mms';
    case DataType.CALLS:
        return 'common.data-type.calls';
    case DataType.PHOTOS:
        return 'common.data-type.photos';
    case DataType.GEOLOCATION:
        return 'common.data-type.geolocation';
    case DataType.PRINTED_DOCS:
        return 'common.data-type.printed-docs';
    case DataType.LOGON_EVENTS:
        return 'common.data-type.logon-events';
    case DataType.EXTERNAL_MEDIA:
        return 'common.data-type.external-media';
    case DataType.WEBSITES_SEARCH:
        return 'common.data-type.websites-search';
    case DataType.PROCESSES:
        return 'common.data-type.processes';
    case DataType.FILES_TRACKING:
        return 'common.data-type.files-tracking';
    default:
        return '';
    }
}


export function dataTypeBaseEndpoint(dataType: DataType): string
{
    switch (dataType)
    {
    case DataType.KEYWORDS:
        return 'keystrokes';
    case DataType.WEBSITES:
        return 'websites';
    case DataType.SCREENSHOTS:
        return 'images/screenshot/automatic';
    case DataType.PROGRAMS:
        return 'installed-apps';
    case DataType.PROCESSES:
        return 'process';
    case DataType.EMAILS:
        return 'emails';
    case DataType.SCREEN_CAPTURES:
        return 'images/screenshot/manual';
    case DataType.SMS:
        return 'text-sms';
    case DataType.MMS:
        return 'text-mms';
    case DataType.CALLS:
        return 'calls-history';
    case DataType.PHOTOS:
        return 'images/photo';
    case DataType.GEOLOCATION:
        return 'geolocation';
    case DataType.PRINTED_DOCS:
        return 'printed-documents';
    case DataType.LOGON_EVENTS:
        return 'logon-events';
    case DataType.FILES_TRACKING:
        return 'files-tracking';
    case DataType.EXTERNAL_MEDIA:
        return 'external-devices';
    case DataType.WEBSITES_SEARCH:
        return 'searches';
    case DataType.SCREENSHOTS_META:
        return 'images/screenshotsmeta/automatic';

    default:
        return '';
    }
}

