import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DropdownSelector } from 'src/components/dropdown-selector/DropdownSelector';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { SessionLogs } from 'src/models/sessionLogs.enum';
import {
    selectSessionLogsMenu,
    setSessionSettingsMenu,

} from 'src/state/administration/administrationSlice';
import { Role } from 'src/models/roles.enum';
import { changeSessionFilters } from 'src/state/captures/capturesSlice';
import { changeDateToStringDate } from 'src/utils/common';
import { addDays, endOfDay, startOfDay } from 'date-fns';

export function SessionLogsSelect(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const role = useSelector(selectRole);
    const adminSettingMenu = useSelector(selectSessionLogsMenu);

    function handleTabChange(value: unknown): void
    {
        dispatch(setSessionSettingsMenu(value as SessionLogs));
        dispatch(changeSessionFilters({
            startDate: changeDateToStringDate(addDays(startOfDay(new Date()), -2)),
            endDate: endOfDay(new Date()).toISOString(),
        }));
    }
    let menuItems = [];
    if (role === Role.Officer)
    {

        menuItems = [
            {
                value: SessionLogs.UserSessionLogs,
                name: t('user-menu.my-log'),
            },
        ];
    }
    else
    {
        menuItems = [
            {
                value: SessionLogs.UserSessionLogs,
                name: t('user-menu.my-log'),
            },
            {
                value: SessionLogs.AssignedOfficersSessionLogs,
                name: t('user-menu.assigned-officer-log'),
            },
        ];
    }

    return (
        <DropdownSelector
            options={menuItems}
            fullWidth
            label={t('user-menu.session-logs')}
            onChange={handleTabChange}
            value={adminSettingMenu}
        />
    );
}
