import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ScreenshotData, ScreenshotMetaData }
    from 'src/models/captures/screenshotData.model';
import {
    changeScreenshot,
    changeScreenshotDeviceId,
    selectScreenshot,
    selectScreenshotsDeviceId,
    selectScreenshotsStatus,
    selectUpdatedFlag,
    screenshotFilterFlags,
    selectFilters,
    changeFilters,
    resetaiFlagFilterData,
    changescreenshotGridViewFilters,
    selectScreenshotsDeviceById,
    getScreenshotsMetadevicebyid,
} from 'src/state/captures/capturesSlice';
import GridImageModalView from 'src/components/grid-image-view/gridmodal';
import 'react-image-gallery/styles/css/image-gallery.css';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { DataType } from 'src/models/dataType.enum';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    TableExportFormat,
    usePDFExport,
    useTableExporter,
    usePDFExportSetter,
} from 'src/components/table-exporter/TableExporterProvider';
import TableExporter from 'src/components/table-exporter/TableExporter';
import {
    CircularProgress,
    Typography,
    Theme,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { DataTypeFlagFilter } from 'src/templates/data-type-flag-filter/DataTypeFlagFilter';
import { Flag } from 'src/models/flag.enum';
import { Platform } from 'src/models/platforms.enum';
import {
    changeDateToStringDate,
    downloadImages,
    handleDragStart,
    processAndGeneratePDFs,
    toggleFullScreen,
    zoomImage,
} from 'src/utils/common';
import { SCREENSHOTMETAPAGE } from 'src/models/alertsType.enum';

interface ScreenshotDetailsGridProps {
    images: Array<ImageItem>
}
interface ImageItem {
    flag: Flag,
    device: string,
    username: string,
    platform: Platform,
    captureStartDate: string,
    captureEndDate: string,
    capturedDate: string,
    receivedDate: string,
    id: string,
    date: Date,
    fileId: string,
    fileUrl: string,
    count: number | undefined,
    totalCount: number | undefined,
    onClick: () => void,
    onCheck: (image: ImageItem,checked:boolean) => void,
    aiScanStatus: number,
    alertWords: string[],

}

export function ScreenshotmetaGrid(
    data: ScreenshotData,
    { images = [] }: ScreenshotDetailsGridProps
): React.ReactElement
{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const pdfExportSetter = usePDFExportSetter();
    const screenshotsDataDeviceId = useSelector(selectScreenshotsDeviceId);
    const ScreenshotsDataDeviceById = useSelector(selectScreenshotsDeviceById);
    const filterFlagData = useSelector(screenshotFilterFlags);
    const pdfExporter = usePDFExport();
    const [format, setFormat]
        = useState<TableExportFormat | undefined>(undefined);
    const [onlyAW, setOnlyAW] = useState<boolean>(false);
    const updatedFlag = useSelector(selectUpdatedFlag);
    const [clickedImage, setClickedImage]= useState<ImageItem | undefined>(undefined);
    const screenshotData = useSelector(selectScreenshot);
    const screenshotsStatus = useSelector(selectScreenshotsStatus);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [detailOpen, setDetailOpen] = React.useState(false);
    const [selectedImages, setSelectedImages]
        = React.useState<Array<ImageItem>>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [pdfType, setPdfType] = React.useState('');
    const { setExportFormat } = useTableExporter();
    const [screenShotDetails, setScreenShotDetails]
        = useState<ScreenshotMetaData | undefined>(undefined);
    const [scale, setScale] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const [isDateFilterChanged, setIsDateFilterChanged] = useState(false);
    const [load, setLoad] = useState(false);
    const filters = useSelector(selectFilters);
    const receivedDate = data?.receivedDate;
    let startDate = null;
    let endDate: string | number | Date | null = null;

    if (receivedDate)
    {
        startDate = new Date(receivedDate);
        endDate = new Date(receivedDate);
        // Set start of day
        startDate.setHours(0, 0, 0, 0);
        // Set end of day
        endDate.setHours(23, 59, 59, 999);
    }
    const [selectedTime, setSelectedTime] = useState(endDate);
    const [selectedStartTime, setSelectedStartTime] = useState(startDate);
    useEffect(() =>
    {
        dispatch(changeScreenshotDeviceId(screenshotsDataDeviceId?.contents[0]));
        dispatch(changeScreenshot(screenshotsDataDeviceId?.contents[0]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenshotsDataDeviceId, updatedFlag]);

    useEffect(() =>
    {
        window.addEventListener('wheel', handleScroll);
        return () =>
        {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [isFullScreen]);

    useEffect(() =>
    {
        const updatedFilters = { ...filters, isGridView: true };
        dispatch(changeFilters(updatedFilters));
        dispatch(changescreenshotGridViewFilters(true));
        dispatch(resetaiFlagFilterData('viewData'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() =>
    {
        setIsFullScreen(false);
        setScale(1);
    }, [detailOpen]);


    async function handlerPDFExport(tableExportFormat: TableExportFormat):
        Promise<void>
    {
        setFormat(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF && pdfExporter)
        {
            await TableExporter.asPDF(pdfExporter);
        }
        else
        {
            setExportFormat(tableExportFormat);
        }
        setFormat(undefined);
    }
    // eslint-disable-next-line
    const handleScroll = (event: any): void => {
        zoomImage(setScale, setImagePosition, event);
    };

    const dragFunction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>):
        void =>
    {
        handleDragStart(
            scale,
            imagePosition,
            setImagePosition,
            event
        );
    };

    const handleDetailOpen = (id: string): void =>
    {
        const newData = ScreenshotsDataDeviceById?.results.find(
            (image: { id: string; }) =>
                image.id === id
        ) ?? {};
        setScreenShotDetails(newData);
        setDetailOpen(true);
        const clickedImage = ScreenshotsDataDeviceById?.results.find(
            (image: { id: string; }) => image.id === id
        );
        handleImageClick(clickedImage as unknown as ImageItem);
    };

    const handleSetSelectedImages = (
        image: ImageItem,
        checked: boolean
    ): void =>
    {
        if (image)
        {
            if (checked)
            {
                setSelectedImages((prevSelectedImages: ImageItem[]) => [
                    ...prevSelectedImages,
                    image,
                ]);
                setSelectedFileIds((prevSelectedFileIds: string[]) => [
                    ...prevSelectedFileIds,
                    image.fileId,
                ]);
            }
            else
            {
                setSelectedImages((prevSelectedImages: ImageItem[]) =>
                    prevSelectedImages.filter(
                        (selectedImage) => selectedImage.fileId !== image.fileId
                    ));
                setSelectedFileIds((prevSelectedFileIds: string[]) =>
                    prevSelectedFileIds.filter(
                        (fileId) => fileId !== image.fileId
                    ));
            }
        }
    };

    const handleImageClick = (image: ImageItem): void =>
    {
        setClickedImage(image);
    };
    const detailItems = [
        {
            label: t('data-type.table.captured-date'),
            text: (data: ScreenshotMetaData) =>
                new Date(
                    data?.clientCreatedAt.toLocaleString()
                ).toLocaleString(),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: ScreenshotMetaData) => data.userName,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: ScreenshotMetaData) => data.userName,
        },
        {
            label: t('data-type.table.platform'),
            text: (data: ScreenshotMetaData) => data.platform,
        },
        {
            label: t('data-type.table.number-of-screenshots'),
            text: (data: ScreenshotMetaData) =>
                ScreenshotsDataDeviceById?.total,
        },
        {
            label: t('data-type.table.alert-word'),
            text: (data: ScreenshotMetaData) => data.alertWords?.join(', '),
        },
    ];

    const handleOnClick = (type: string): void =>
    {
        if (type === 'selected')
        {
            if (selectedImages.length > 0)
            {
                pdfExportSetter({
                    type: DataType.SCREENSHOTS,
                    recordIds: selectedImages.map((item) => item.id),
                });
                setPdfType('selected');
            }
        }
    };

    useEffect(() =>
    {
        if (pdfType)
        {
            handlerPDFExport(TableExportFormat.PDF);
        }
        setPdfType('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfType]);

    const [loading, setLoading] = useState(true);

    useEffect(() =>
    {
        setTimeout(() =>
        {
            setLoading(false);
        }, 2000);
    }, [screenshotData]);

    if ((loading &&
        window.location.pathname
        !== SCREENSHOTMETAPAGE) ||
        (screenshotsStatus
            !== 'Success' &&
            window.location.pathname
            !== SCREENSHOTMETAPAGE))
    {
        return (
            <div >
                <CircularProgress />
            </div>
        );
    }
    const imageUrl = clickedImage?.fileUrl;
    // eslint-disable-next-line
    const filteredImages = ScreenshotsDataDeviceById?.results
        .filter((screen: {
            // eslint-disable-next-line
            alertWords: string | any[];
            aiScanStatus: number; flag: string;
        }) =>
        {
            const alertWordsFilter = onlyAW
                ? (screen?.alertWords &&
                    screen.alertWords.length > 0) : true;
            const flagFilter = filterFlagData?.length
                ? (
                    (filterFlagData.includes('aiScanStatus') &&
                        screen.aiScanStatus === 2) ||
                    (filterFlagData.includes('NONE') &&
                        screen.flag === undefined) ||
                    filterFlagData.includes(screen.flag)
                )
                : true;
            return alertWordsFilter && flagFilter;
        })
        .sort((
            a: { clientCreatedAt: string | number | Date; },
            b: { clientCreatedAt: string | number | Date; }
        ) =>
        {
            const dateA = new Date(a.clientCreatedAt).valueOf();
            const dateB = new Date(b.clientCreatedAt).valueOf();
            return dateA - dateB;
        });
    const onChangeDateFilter = (
        // eslint-disable-next-line
        selecteDate: any,
        // eslint-disable-next-line
        newdate?: any,
        type?: string
    ): void =>
    {
        if (newdate instanceof Date && selecteDate !== null)
        {
            const currentDate = new Date(selecteDate);
            const newTime = new Date(newdate);
            const updatedDateTime = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                newTime.getHours(),
                newTime.getMinutes(),
                newTime.getSeconds()
            );


            if (type === 'from')
            {
                // eslint-disable-next-line
                setSelectedStartTime(updatedDateTime)
            }
            else
            {
                // eslint-disable-next-line
                setSelectedTime(updatedDateTime);
            }
            let startDateTime;
            if (type === 'from')
            {
                startDateTime = changeDateToStringDate(new Date(updatedDateTime));
            }
            else if (selectedStartTime)
            {
                startDateTime = changeDateToStringDate(new Date(selectedStartTime));
            }
            else
            {
                startDateTime = undefined;
            }
            let endDateTime;
            if (type === 'to')
            {
                endDateTime = changeDateToStringDate(new Date(updatedDateTime));
            }
            else if (selectedTime)
            {
                endDateTime = changeDateToStringDate(new Date(selectedTime));
            }
            else
            {
                endDateTime = undefined;
            }
            let dateFirst;
            if (type === 'from')
            {
                dateFirst = changeDateToStringDate(new Date(updatedDateTime));
            }
            else
            {
                dateFirst = selectedStartTime ?
                    changeDateToStringDate(new Date(selectedStartTime)) : undefined;
            }
            let dateLast;
            if (type === 'to')
            {
                dateLast = changeDateToStringDate(new Date(updatedDateTime));
            }
            else
            {
                dateLast = selectedTime ?
                    changeDateToStringDate(new Date(selectedTime)) : undefined;
            }

            dispatch(getScreenshotsMetadevicebyid({
                id: data.id,
                startDate: startDateTime,
                endDate: endDateTime,
                username: data.username,
                pageNumber: 1,
                entriesPerPage: 0,
                deviceIds: data.deviceId,
                isDateFilterChanged,
                dateFirst,
                dateLast,
            }));
        }
    };
    const dateTypeOnChange = (dateType: boolean): void =>
    {
        setIsDateFilterChanged(dateType);
        dispatch(getScreenshotsMetadevicebyid({
            id: data.id,
            startDate: selectedStartTime ?
                changeDateToStringDate(new Date(selectedStartTime)) :
                undefined,
            endDate: selectedTime ?
                changeDateToStringDate(new Date(selectedTime)) :
                undefined,
            username: data.username,
            pageNumber: 1,
            entriesPerPage: 0,
            deviceIds: data.deviceId,
            isDateFilterChanged: dateType,
            dateFirst: selectedStartTime ?
                changeDateToStringDate(new Date(selectedStartTime)) : undefined,
            dateLast: selectedTime ? changeDateToStringDate(new Date(selectedTime)) : undefined,
        }));
    };
    const handleAlltoPdf = (): void => {
        if (filteredImages) {
            const recordIds = filteredImages?.map((item: { id: string }) => item.id);
            processAndGeneratePDFs(
                recordIds?recordIds:[],
                DataType.SCREENSHOTS,
                setDownloadPercentage,
                null,
                setLoad,
                null
            );
        }
    };
    function onChangeSelectFilter(value: string): void
    {
        dispatch(getScreenshotsMetadevicebyid({
            id: data.id,
            startDate: selectedStartTime ?
                changeDateToStringDate(new Date(selectedStartTime)) :
                undefined,
            endDate: selectedTime ?
                changeDateToStringDate(new Date(selectedTime)) :
                undefined,
            username: data.username,
            pageNumber: 1,
            entriesPerPage: 0,
            deviceIds: data.deviceId,
            isDateFilterChanged,
            searchValue: value,
            dateFirst: selectedStartTime ?
                changeDateToStringDate(new Date(selectedStartTime)) : undefined,
            dateLast: selectedTime ? changeDateToStringDate(new Date(selectedTime)) : undefined,
        }));
    }
    return (
        <>
            <DataDetailsModal<ScreenshotMetaData>
                title={t('data-type.details.screenshots-title')}
                open={detailOpen}
                data={screenShotDetails}
                detailItems={detailItems}
                onClose={() => setDetailOpen(false)}
                comments={{
                    contentId: clickedImage?.id,
                    type: CommentType.SCREENSHOTS,
                }}
            >

                {ScreenshotsDataDeviceById?.results && (
                    <>
                        <Button
                            variant="text"
                            startIcon={
                                isLoading ?
                                    <CircularProgress size="1rem" /> :
                                    <DownloadIcon />
                            }
                            onClick={() =>
                            {
                                if (imageUrl)
                                {
                                    downloadImages(
                                        imageUrl,
                                        setIsLoading,
                                        null,
                                        clickedImage?.fileId
                                    );
                                }
                            }}
                        >
                            JPEG
                        </Button>
                        {isFullScreen ? (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 999,
                                    cursor: 'pointer',
                                }}
                                onMouseDown={dragFunction}
                            >
                                <button
                                    onClick={() =>
                                    {
                                        toggleFullScreen(
                                            isFullScreen,
                                            setIsFullScreen,
                                            setScale,
                                            setImagePosition
                                        );
                                    }}
                                    style={{
                                        position: 'fixed',
                                        top: '5%',
                                        right: '5%',
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontSize: 'xx-large',
                                        color: 'white',
                                        zIndex: 999,
                                    }}
                                >
                                    X
                                </button>
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        transform: `scale(${scale}) 
                                        translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                                        transition: 'transform 0.3s',
                                        cursor: imagePosition.x > 0 || imagePosition.y > 0 ?
                                            'grabbing' : 'pointer',
                                    }}
                                    src={clickedImage?.fileUrl}
                                    alt={''}
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                {
                                    toggleFullScreen(
                                        isFullScreen,
                                        setIsFullScreen,
                                        setScale,
                                        setImagePosition
                                    );
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                            >
                                <img
                                    style={{ width: '100%', height: 'auto' }}
                                    src={clickedImage?.fileUrl}
                                    alt={''}
                                />
                            </div>
                        )}
                    </>
                )}
            </DataDetailsModal>
            <div style={{ width: '100%', height: 'auto' }}>
                <Box>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                gap: 10,
                            }}
                        >

                            <Button
                                variant="text"
                                startIcon={load === true
                                    ? <CircularProgress size="1rem" />
                                    : <DownloadIcon />
                                }
                                onClick={() =>
                                {
                                    setLoad(true);
                                    handleAlltoPdf();
                                }}
                            >
                                {t('common.button.all-to-pdf')}
                                {' '}
                                {load === true && Math.round(downloadPercentage) + '%'}
                            </Button>

                            <Button
                                variant="text"
                                startIcon={
                                    isLoading ?
                                        <CircularProgress size="1rem" /> :
                                        <DownloadIcon />
                                }
                                onClick={() =>
                                {
                                    downloadImages(
                                        filteredImages,
                                        setIsLoading
                                    );
                                }}
                            >
                                {t('common.button.all-to-jpeg')}
                            </Button>

                            <FormControlLabel
                                sx={{ display: 'inline-flex', alignItems: 'center' }}
                                control={(
                                    <Checkbox

                                        sx={{
                                            fontSize: '1rem',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                        checked={isDateFilterChanged}
                                        onChange={() =>
                                        {
                                            dateTypeOnChange(true);

                                        }}
                                    />
                                )}
                                label={(
                                    <span style={{ color: '#000000' }}>
                                        {t('data-type.table.captured-date')}
                                    </span>
                                )}
                            />
                            <FormControlLabel
                                sx={{ display: 'inline-flex', alignItems: 'center' }}
                                control={(
                                    <Checkbox

                                        sx={{
                                            fontSize: '1rem',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                        checked={!isDateFilterChanged}
                                        onChange={() =>
                                        {
                                            dateTypeOnChange(false);
                                        }
                                        }

                                    />
                                )}
                                label={(
                                    <span style={{ color: '#000000' }}>
                                        {t('data-type.table.received-date')}
                                    </span>
                                )}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                gap: 10,
                                marginTop: '10px',
                                marginRight: '16px',
                            }}
                        >

                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                <DateTimePicker
                                    label={t('data-type.filters.start-date')}
                                    value={
                                        selectedStartTime
                                    }
                                    onChange={(newValue) =>
                                    {


                                        onChangeDateFilter(selectedStartTime, newValue, 'from');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            onKeyDown={(e) => e.preventDefault()}
                                            sx={{
                                                backgroundColor: (theme: Theme) =>
                                                    theme.palette.common.white,
                                                borderRadius: 1,
                                            }}
                                            {...params}
                                        />
                                    )}
                                />

                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>


                                <DateTimePicker
                                    label={t('data-type.filters.end-date')}
                                    value={
                                        selectedTime
                                    }
                                    onChange={(newValue) =>
                                    {
                                        onChangeDateFilter(selectedTime, newValue, 'to');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            onKeyDown={(e) => e.preventDefault()}
                                            sx={{
                                                backgroundColor: (theme: Theme) =>
                                                    theme.palette.common.white,
                                                borderRadius: 1,
                                            }}
                                            {...params}
                                        />
                                    )}
                                />

                            </LocalizationProvider>
                        </div>

                    </div>


                    {selectedImages.length > 0 ? (
                        <>
                            <Button
                                variant="text"
                                startIcon={TableExporter.loading
                                    && format === TableExportFormat.PDF
                                    ? <CircularProgress size="1rem" />
                                    : <DownloadIcon />
                                }
                                onClick={(e) =>
                                {
                                    handleOnClick('selected');
                                }}
                            >
                                {t('common.button.selected-to-pdf')}
                            </Button>
                            <Button
                                variant="text"
                                startIcon={
                                    selectLoading ?
                                        <CircularProgress size="1rem" /> :
                                        <DownloadIcon />}
                                onClick={() =>
                                {
                                    downloadImages(
                                        selectedImages,
                                        null,
                                        setSelectLoading
                                    );
                                }}
                            >
                                {t('common.button.selected-to-jpeg')}
                            </Button>
                        </>
                    )
                        // eslint-disable-next-line
                        : null}

                    {(ScreenshotsDataDeviceById?.results
                        .some((image:
                            // eslint-disable-next-line
                            { alertWords: string | any[]; }) =>
                            image.alertWords?.length > 0)) ?
                        (
                            <Button
                                variant="contained"
                                sx={{ float: 'right', m: 2 }}
                                onClick={(e) =>
                                {
                                    setOnlyAW(!onlyAW);
                                }}
                            >
                                {!onlyAW ? t('form.View-alert-words') :
                                    t('form.No-alert-words')}
                            </Button>
                        ) : null}
                    <div
                        style={{
                            width: '12%',
                            float: 'right',
                            margin: 20,
                        }}
                    >
                        <TextField
                            name="firstname"
                            label={t('data-type.table.search')}

                            autoComplete="off"

                            onChange={event =>
                                onChangeSelectFilter(event.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            width: '15%',
                            float: 'right',
                            margin: 20,
                        }}
                    >
                        <DataTypeFlagFilter />
                    </div>
                </Box>
                {filteredImages &&
                    ScreenshotsDataDeviceById.results &&
                    filteredImages.length > 0 ? (
                        <GridImageModalView
                            images={
                                filteredImages
                                    .map((screen: {
                                    receivedDate: Date;
                                    capturedDate: Date;
                                    createdAt: Date;
                                    captureEndDate: Date;
                                    screen: string;
                                    captureStartDate: Date;
                                    platform: string;
                                    username: string;
                                    device: string; id: string;
                                    clientCreatedAt: Date;
                                    fileId: string;
                                    fileUrl: string;
                                    aiScanStatus: number;
                                    // eslint-disable-next-line
                                    alertWords: any;
                                    flag: string;
                                    comments: number;
                                }, index: number) => ({
                                        id: screen.id,
                                        date: isDateFilterChanged ?
                                            screen.clientCreatedAt :
                                            screen.createdAt,
                                        fileId: screen.fileId,
                                        fileUrl: screen.fileUrl,
                                        count: index + 1,
                                        totalCount: filteredImages.length,
                                        onClick: () => handleDetailOpen(screen.id),
                                        onCheck: handleSetSelectedImages,
                                        aiScanStatus: screen.aiScanStatus,
                                        alertWords: screen.alertWords || [],
                                        flag: screen.flag,
                                        device: screen.device,
                                        deviceId: screen.device,
                                        username: screen.username,
                                        platform: screen.platform,
                                        captureStartDate: screen.captureStartDate,
                                        captureEndDate: screen.captureEndDate,
                                        capturedDate: screen.capturedDate,
                                        receivedDate: screen.receivedDate,
                                        comments: screen.comments,
                                    })) ?? []}
                            alertWords={!onlyAW}
                            selectedFileIds={selectedFileIds}
                        />
                    )
                    : screenshotsStatus !== 'Success' ? (
                        <div >
                            <CircularProgress />
                        </div>
                    )
                        :
                        (
                            <Typography
                                variant="h3"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.common.lightGrey,
                                    margin: '4rem auto',
                                    textAlign: 'center',
                                }}
                            >
                                {t('data-type.table.no-content')}
                            </Typography>
                        )}
            </div>
        </>
    );
}
