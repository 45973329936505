import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    changeAdminDeviceFilters,
    changeAdminFilters,
    selectAdminFilters,
    selectAdminDeviceFilters,
    selectAdminSettingsMenu,
} from 'src/state/administration/administrationSlice';
import {
    FormControl,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdminSettings } from 'src/models/adminSettings.enum';
import { 
    changeDeviceDetailsFilters,
    selectDeviceDetailsFilters 
} from 'src/state/administrationMeta/administrationMetaSlice';
import { ADMIN_SETTINGS } from 'src/utils/routes';

export function AdminSettingSearchFilter(): React.ReactElement
{
    const dispatch = useDispatch();
    const searchFilter = useSelector(selectAdminFilters);
    const searchDeviceFilter = useSelector(selectAdminDeviceFilters);
    const isDeviceSearch= window.location.pathname.split('/')[2]=== 'client'
        && window.location.pathname.split('/')[4] === 'devices';
    const { t } = useTranslation();
    const adminSettingMenu = useSelector(selectAdminSettingsMenu);
    const isDeviceInfoPage = window.location.pathname === ADMIN_SETTINGS &&
        adminSettingMenu === AdminSettings.DeviceInformation;
    const deviceDetailsFilters = useSelector(selectDeviceDetailsFilters);
    let searchValue;
    if (isDeviceSearch) {
        searchValue = searchDeviceFilter?.searchValue;
    } else if (isDeviceInfoPage) {
        searchValue = deviceDetailsFilters?.searchValue;
    } else {
        searchValue = searchFilter?.searchValue;
    }

    function onChangeAdminFilter(value: string): void
    {
        if(isDeviceSearch)
        {
            dispatch(
                changeAdminDeviceFilters({
                    searchValue: value,
                })
            )
        }
        else if (isDeviceInfoPage)
        {
            dispatch(
                changeDeviceDetailsFilters({
                    searchValue: value,
                })
            );
        }
        else
        {
            dispatch(
                changeAdminFilters({
                    searchValue: value,
                })
            );
        }
    }

    return (
        <FormControl>
            <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                value={searchValue}
                autoComplete="on"
                onChange={event => onChangeAdminFilter(event.target.value)}
            />
        </FormControl>
    );
}
