import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';

import {
    selectAgencyFilterOptions,
    selectClientFilterOptions,
    selectDeviceFilterOptions,
    selectAgencyFilterOptionsStatus,
    selectClientFilterOptionsStatus,
    selectDeviceFilterOptionsStatus,
    getAgencyFilterOptions,
    getOfficerFilterOptions,
    getClientFilterOptions,
    getDeviceFilterOptions,
    selectOfficerFilterOptions,
    selectOfficerFilterOptionsStatus,
    getActiveDevices,
    getInActiveDevices,
    getRecord,
    changeAlertsFilter,
    changeAgencyAlertsFilter,
    selectAlertsFilter,
    changeOfficerAlertsFilter,
    changeClientAlertsFilter,

} from 'src/state/captures/capturesSlice';
import { FiltersBar } from 'src/components/filters-bar/FiltersBar';
import {
    DropdownSelector,
} from 'src/components/dropdown-selector/DropdownSelector';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';
import { DateFilterChange } from 'src/utils/dateUtils';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { PHOTOS, SCREENCAPTURES, SCREENSHOTS, TEXTMMS } from 'src/models/alertsType.enum';


export interface DataTypeAlertsTableProps {
    selectedValue: string;
}
export function DataTypeAlertsFilters(
    { selectedValue }: Readonly<DataTypeAlertsTableProps>
): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const officerFilters = useSelector(selectOfficerFilterOptions);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const clientFilters = useSelector(selectClientFilterOptions);
    const deviceFilters = useSelector(selectDeviceFilterOptions);
    const officerFiltersStatus = useSelector(selectOfficerFilterOptionsStatus);
    const agencyFiltersStatus = useSelector(selectAgencyFilterOptionsStatus);
    const clientFiltersStatus = useSelector(selectClientFilterOptionsStatus);
    const deviceFiltersStatus = useSelector(selectDeviceFilterOptionsStatus);
    const alertsFilter = useSelector(selectAlertsFilter);

    const [isDateFilterChanged, setIsDateFilterChanged] = useState(false);
    const [startDate, setStartDate] = useState<Date | null | undefined>();
    const [endDate, setEndDate] = useState<Date | null | undefined>();

    function onChangeSelectFilter(
        field: keyof CaptureFilters
    ): (value: unknown) => void
    {
        return value =>
        {
            dispatch(
                changeAlertsFilter({
                    [field]: value ? value as string : undefined,
                })
            );
        };
    }
    function onChangeAgencyFilter(agency: unknown): void
    {
        const agencyId = agency as string ?? undefined;
        const officerId = agencyId ? alertsFilter.officer : undefined;
        const clientId = agencyId ? alertsFilter.client : undefined;

        dispatch(changeAgencyAlertsFilter(agencyId));
        dispatch(getOfficerFilterOptions({ agencyId }));
        dispatch(getClientFilterOptions({ agencyId, officerId }));
        dispatch(getDeviceFilterOptions({
            agencyId,
            officerId,
            clientId,
            isDisableRecords: alertsFilter.isDisableRecords,
        }));
        dispatch(getActiveDevices({
            agencyId,
            officerId,
            clientId,
            pageNumber: 1,
            entriesPerPage: 10,
        }));
        dispatch(getInActiveDevices({
            agencyId,
            officerId,
            clientId,
            pageNumber: 1,
            entriesPerPage: 10,
            details: false,
        }));

    }
    function onChangeOfficerFilter(officer: unknown): void
    {
        const officerId = officer as string ?? undefined;
        dispatch(changeOfficerAlertsFilter(officerId));
        dispatch(getClientFilterOptions({
            agencyId: alertsFilter.agency,
            officerId,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId: alertsFilter.agency,
            officerId,
            isDisableRecords: alertsFilter.isDisableRecords,
        }));
        dispatch(getActiveDevices({
            agencyId: alertsFilter.agency,
            officerId,
            pageNumber: 1,
            entriesPerPage: 10,
        }));
        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId,
            pageNumber: 1,
            entriesPerPage: 10,
            details: false,
        }));


    }
    function onChangeClientFilter(client: unknown): void
    {
        const clientId = client as string ?? undefined;
        dispatch(changeClientAlertsFilter(clientId));
        dispatch(getDeviceFilterOptions({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId,
            isDisableRecords: alertsFilter.isDisableRecords,
        }));
        dispatch(getActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId,
            pageNumber: 1,
            entriesPerPage: 10,
        }));
        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId,
            pageNumber: 1,
            entriesPerPage: 10,
            details: false,
        }));


    }

    const onChangeDateCheckedFilter = (): void =>
    {
        setIsDateFilterChanged(!isDateFilterChanged);
        const updatedFilters = { ...alertsFilter, isDateFilterChanged: true };
        dispatch(changeAlertsFilter(updatedFilters));
    };

    const onChangeDefaultFilter = (): void =>
    {
        setIsDateFilterChanged(!isDateFilterChanged);
        const updatedFilters = { ...alertsFilter, isDateFilterChanged: false };
        dispatch(changeAlertsFilter(updatedFilters));
    };

    const onChangeDateFilter = (
        field: keyof CaptureFilters,
        // eslint-disable-next-line
        newdate?: any
    ): void =>
    {
        const dateTime = DateFilterChange(field, newdate, startDate, endDate);
        setStartDate(dateTime.startDateTime);
        setEndDate(dateTime.endDateTime);
        dispatch(changeAlertsFilter({
            startDate: dateTime.startDateTime,
            endDate: dateTime.endDateTime,
        }));
    };
    // eslint-disable-next-line
    const onToggleChange = (event: any): void =>
    {
        const updatedFilters = { 
            ...alertsFilter,
            device:undefined,
            isDisableRecords: event.target.checked,
            alertwordsOnly: undefined 
        };
        dispatch(changeAlertsFilter(updatedFilters));
        dispatch(getDeviceFilterOptions({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            isDisableRecords: event.target.checked,
        }));
    };
    // eslint-disable-next-line
    const onTogglegalleryChange = (event: any): void =>
    {
        const updatedFilters = { ...alertsFilter, isGridView: event.target.checked, alertwordsOnly: undefined  };
        dispatch(changeAlertsFilter(updatedFilters));
    };
    useEffect(() =>
    {
        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage: 10,
            details: false,
        }));
        dispatch(getActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage: 10,
        }));
        dispatch(getRecord());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if (!userIsInRole([Role.Officer]))
        {
            dispatch(getAgencyFilterOptions());
        }
        dispatch(getOfficerFilterOptions({ agencyId: alertsFilter.agency }));
        dispatch(getClientFilterOptions({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            isDisableRecords: alertsFilter.isDisableRecords,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,
        alertsFilter.startDate,
        alertsFilter.endDate,
        selectedValue]);



    return (
        <FiltersBar>
            {
                !userIsInRole([Role.Officer]) && (
                    <DropdownSelector
                        options={agencyFilters ?? []}
                        label={t('data-type.filters.agency')}
                        labelId="agency-filter-label"
                        value={alertsFilter.agency ?? ''}
                        onChange={onChangeAgencyFilter}
                        sx={{ width: '16rem' }}
                        clearable={true}
                        isLoading={agencyFiltersStatus === RequestStatus.InProgress}
                        inputProps={{
                            sx: {
                                backgroundColor: (theme: Theme) => theme.palette.common.white,
                            },
                        }}
                    />
                )
            }
            {
                !userIsInRole([Role.Officer]) && (
                    <DropdownSelector
                        options={officerFilters ?? []}
                        label={t('data-type.filters.officer')}
                        labelId="officer-filter-label"
                        value={alertsFilter.officer ?? ''}
                        onChange={onChangeOfficerFilter}
                        sx={{ width: '16rem' }}
                        clearable={true}
                        isLoading={officerFiltersStatus === RequestStatus.InProgress}
                        inputProps={{
                            sx: {
                                backgroundColor: (theme: Theme) => theme.palette.common.white,
                            },
                        }}
                    />
                )
            }

            <DropdownSelector
                options={clientFilters ?? []}
                label={t('data-type.filters.client')}
                labelId="client-filter-label"
                value={alertsFilter.client ?? ''}
                onChange={onChangeClientFilter}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={clientFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <DropdownSelector
                options={deviceFilters ?? []}
                label={t('data-type.filters.device')}
                labelId="device-filter-label"
                value={alertsFilter.device ?? ''}
                onChange={onChangeSelectFilter('device')}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={deviceFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={t('data-type.filters.start-date')}
                    value={
                        alertsFilter.startDate
                            ? new Date(alertsFilter.startDate)
                            : null
                    }
                    onChange={(newValue) =>
                        onChangeDateFilter('startDate', newValue)
                    }
                    renderInput={(params) => (
                        <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            sx={{
                                backgroundColor: (theme: Theme) =>
                                    theme.palette.common.white,
                                borderRadius: 1,
                            }}
                            {...params}
                        />
                    )}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={t('data-type.filters.end-date')}
                    value={
                        alertsFilter.endDate
                            ? new Date(alertsFilter.endDate)
                            : null
                    }
                    onChange={(newValue) =>
                        onChangeDateFilter('endDate', newValue)
                    }
                    renderInput={(params) => (
                        <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            sx={{
                                backgroundColor: (theme: Theme) =>
                                    theme.palette.common.white,
                                borderRadius: 1,
                            }}
                            {...params}

                        />
                    )}
                />
            </LocalizationProvider>
            <FormGroup row style={{ marginTop: '-10px' }}>

                <FormControlLabel
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                    control={(
                        <Checkbox

                            sx={{
                                fontSize: '1rem',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            checked={alertsFilter.isDateFilterChanged}
                            disabled={alertsFilter.isDateFilterChanged}
                            onChange={onChangeDateCheckedFilter}
                        />
                    )}
                    label={(
                        <span style={{ color: '#000000' }}>
                            {t('data-type.table.captured-date')}
                        </span>
                    )}
                />
                <FormControlLabel
                    sx={{ display: 'inline-flex', alignItems: 'center', pr: 8 }}
                    control={(
                        <Checkbox

                            sx={{
                                fontSize: '1rem',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            checked={!alertsFilter.isDateFilterChanged}
                            disabled={!alertsFilter.isDateFilterChanged}

                            onChange={onChangeDefaultFilter}
                        />
                    )}

                    label={(
                        <span style={{ color: '#000000' }}>
                            {t('data-type.table.received-date')}
                        </span>
                    )}
                />
                <FormControlLabel
                    control={(
                        <ToggleSwitch
                            sx={{
                                m: 2,
                                '& .MuiSwitch-track': {
                                    backgroundColor: 'darkgray',
                                },
                            }}
                            checked={alertsFilter.isDisableRecords}
                            onClick={(event) =>
                            {
                                onToggleChange(event);
                            }}
                        />
                    )}
                    name="activated"
                    label={t('common.actions.disabled-device')}
                />
                {selectedValue === TEXTMMS ||
                selectedValue === PHOTOS ||
                selectedValue === SCREENCAPTURES ||
                selectedValue === SCREENSHOTS ?
                    (
                        <FormControlLabel
                            control={(
                                <ToggleSwitch
                                    sx={{
                                        m: 2,
                                        '& .MuiSwitch-track': {
                                            backgroundColor: 'darkgray',
                                        },
                                    }}
                                    checked={alertsFilter.isGridView}
                                    onClick={(event) =>
                                    {
                                        onTogglegalleryChange(event);
                                    }}
                                />
                            )}
                            name="activated"
                            label={t('common.actions.gallery-view')}
                        />
                    ) : null }
            </FormGroup>
        </FiltersBar>
    );
}
