import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getClients,
    selectClientSaveStatus,
    selectClientFilters,
    selectClients,
    selectClientsStatus,
    selectBackNavigation,
    BackNavigation,
    BackNavigationValues,
    selectBackNavigationValues,
    selectAdminFilters,
    clearSearchValue,
    clearDeviceSearchValue,
} from 'src/state/administration/administrationSlice';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { useTranslation } from 'react-i18next';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Client } from 'src/models/administration/clientData.model';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { CLIENT_DEVICES_MANAGEMENT } from 'src/utils/routes';
import { noop } from 'src/utils/common';
import DevicesIcon from '@mui/icons-material/Devices';
import ListAltIcon from '@mui/icons-material/ListAlt';

export interface ClientsTableProps {
    onEdit?: (client: Client) => void;
    onConfig?: (client: Client) => void;
    onAlert?: (client: Client) => void;
}

export function ClientsTable({
    onEdit = noop,
    onConfig = noop,
    onAlert = noop,
}: Readonly<ClientsTableProps>): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectClients);
    const filters = useSelector(selectClientFilters);
    const status = useSelector(selectClientsStatus);
    const saveStatus = useSelector(selectClientSaveStatus);
    const navigate = useNavigate();
    const searchFilter = useSelector(selectAdminFilters);
    const backNavigationstatus = useSelector(selectBackNavigation);
    const backNavigationValues = useSelector(selectBackNavigationValues);

    function onPageChange(pageNumber: number): void
    {
        setPage(pageNumber);
        dispatch(getClients({ pageNumber, entriesPerPage, ...searchFilter }));
        dispatch(BackNavigation(false));
    }

    useEffect(() =>
    {
        dispatch(BackNavigation(false));
        // eslint-disable-next-line
    }, [entriesPerPage]);

    useEffect(() =>
    {
        if ( backNavigationstatus === true && !items)
        {
            dispatch(getClients({ pageNumber: page, entriesPerPage, ...searchFilter }));
        }
        // eslint-disable-next-line
    }, [dispatch, entriesPerPage, page, searchFilter, backNavigationstatus]);

    useEffect(() =>
    {
        if (saveStatus === RequestStatus.Deleted || saveStatus === RequestStatus.Success)
        {
            dispatch(getClients({ pageNumber: page, entriesPerPage, ...searchFilter }));
        }
    }, [saveStatus, dispatch, entriesPerPage, searchFilter, page]);
    useEffect(() =>
    {
        if(backNavigationstatus !== true)
        {
            dispatch(getClients({ pageNumber: 1, entriesPerPage, ...filters, ...searchFilter }));
        }
        // eslint-disable-next-line
    }, [dispatch, entriesPerPage, filters, searchFilter]);

    const getEditColumn = (client: Client): React.ReactNode => (
        <Button
            onClick={() => onEdit(client)}
        >
            <EditIcon />
        </Button>
    );
    const getConfigurationColumn = (client: Client): React.ReactNode => (
        <Button
            onClick={() => onConfig(client)}
        >
            <BuildIcon />
        </Button>
    );
    const getAlertwordColumn = (client: Client): React.ReactNode => (
        <Button
            onClick={() => onAlert(client)}
        >
            <ListAltIcon />
        </Button>
    );
    const getDevicesColumn = (id: string): React.ReactNode => (
        <Button
            onClick={() => {
                navigate(generatePath(CLIENT_DEVICES_MANAGEMENT, { id }));
                dispatch(clearDeviceSearchValue());
            }}
        >
            <DevicesIcon />
        </Button>
    );

    const columns: ColumnData<Client>[] = [
        {
            label: t('user.agency'),
            size: 2,
            value: (data: Client) => data.agencyInfo.agencyName,
        },
        {
            label: t('user.account-number'),
            size: 2,
            value: (data: Client) => data.remoteCOMAccount,
        },
        {
            label: t('user.firstname'),
            size: 3,
            value: (data: Client) => data.firstName,
        },
        {
            label: t('user.lastname'),
            size: 3,
            value: (data: Client) => data.lastName,
        },
        {
            label: t('common.actions.edit'),
            size: 1,
            format: (client) => getEditColumn(client),
        },
        {
            label: t('common.nouns.configuration'),
            size: 1,
            format: (client) => getConfigurationColumn(client),
        },
        {
            label: t('data-type.details.alert-words'),
            size: 1,
            align: 'center',
            format: (client) => getAlertwordColumn(client),
        },
        {
            label: t('common.nouns.devices'),
            size: 1,
            format: ({ id }) => getDevicesColumn(id),
        },

    ];

    return (
        <DataTable<Client>
            isLoading={status === RequestStatus.InProgress}
            columns={columns}
            onPageChange={onPageChange}
            entriesPerPage={backNavigationValues ?? entriesPerPage}
            onChangeEntriesPerPage={(values) =>
            {
                setEntriesPerPage(values);
                dispatch(BackNavigationValues(values));
            }}
            {...items}
        />
    );
}
