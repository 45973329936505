import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    selectAlerts,
    selectAlertsStatus,
    selectAlertsFilter,
    getAlertsDetails,
    selectAlertsDetailsStatus,
    selectAlertsDetails,
    updateSeenStatus,
    selectGridAlerts,
    changeAlertsData,
    resetAlertsFilterData,
    resetaiFlagFilterData,
    changescreenshotGridViewFilters,
    changeAlertsIdStatus,
    getalerts,
    getRedFlagged,
    changeAlertsFilter,
    changeTotalRecord,
    changeColumns,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { AlertsData, AlertsDetailsData } from 'src/models/captures/alertsData.model';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { secondsToHHMMSS, timeZoneFormat } from 'src/utils/dateUtils';
import { HighlightText } from 'src/components/highlight-text/HighlightText';
import { Button, CircularProgress, IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { LandingPageDetails } from 'src/templates/landing-page-alerts-table/LandingPageDetails';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import {
    ALL_DATA_TYPE,
    PHOTOS,
    SCREENCAPTURES,
    SCREENSHOTS,
    TEXTMMS,
}
    from 'src/models/alertsType.enum';
import { DataType } from 'src/models/dataType.enum';
import LandingPageAlertsGrid from './LandingPageAlertsGrid';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { checkFilterChange } from 'src/utils/checkFilterChange';

export interface LandingPageAlertsTableProps {
    readonly selectedValue: string;
}

interface SkipValues {
    mainSkip: number;
    collSkip: number;
}

interface PageSkipValues {
    [key: number]: SkipValues;
}
export function LandingPageAlertsTable(
    { selectedValue }: LandingPageAlertsTableProps
): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();

    const alertsFilter = useSelector(selectAlertsFilter);
    const alertsData = useSelector(selectAlerts);
    const alertsGridData = useSelector(selectGridAlerts);
    const alertsStatus = useSelector(selectAlertsStatus);
    const alertsDetailsData = useSelector(selectAlertsDetails);
    const alertsDetailsStatus = useSelector(selectAlertsDetailsStatus);

    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [pageNumbers, setPageNumbers] = useState(1);
    const desktopScreen = useDesktopScreen();
    const [skipValue, setSkipValue] = useState<SkipValues>({ mainSkip: 0, collSkip: 0 });
    const [prevPage, setPrevPage] = useState(0);
    const [currentFilter, setCurrentFilter] = useState(alertsFilter);
    const [pageSkipValues, setPageSkipValues] = useState<PageSkipValues>({ 1: { mainSkip: 0, collSkip: 0 } });

    function onPageChange(pageNumber: number): void
    {
        let skipValues = skipValue;
        if (selectedValue === ALL_DATA_TYPE && alertsData?.resultCount)
        {
            skipValues = { mainSkip: 0, collSkip: alertsData?.resultCount * entriesPerPage };
            if (skipValue.collSkip === alertsData?.resultCount * entriesPerPage && pageNumber > prevPage)
            {
                skipValues.mainSkip = skipValue.mainSkip + entriesPerPage;
            } else if (pageNumber <= prevPage) {
                skipValues.mainSkip = pageSkipValues[pageNumber]?.mainSkip || 0;
                skipValues.collSkip = pageSkipValues[pageNumber]?.collSkip || 0;
            }
            setPrevPage(pageNumber - 1);
            setSkipValue(skipValues);
            setPageSkipValues(prevState => ({
                ...prevState,
                [pageNumber]: skipValues,
            }));
        }
        setPageNumbers(pageNumber);
    }

    const handleEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setPageNumbers(1);
        setEntriesPerPage(newEntriesPerPage);
        const skipValues = { mainSkip: 0, collSkip: 0 };
        const pageSkipValues = { 1: { mainSkip: 0, collSkip: 0 } };
        setSkipValue(skipValues);
        setPageSkipValues(pageSkipValues);
    };
    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (alertsdata: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([alertsdata]);
        }
        else if (!datas.includes(alertsdata))
        {
            setDatas([...datas, alertsdata]);
        }
    };
    const [open, setOpen] = useState(false);

    const handleOpen = (alertsdata: AlertsData): void =>
    {
        openRecordData(alertsdata.id);

        dispatch(getAlertsDetails({ alertsId: alertsdata.id, dataType: alertsdata.dataType }));

        if (!alertsdata.isSeen)
        {
            dispatch(updateSeenStatus({ alertsId: alertsdata.id, dataType: alertsdata.dataType }));
        }
        setOpen(true);
    };

    useEffect(() =>
    {
        dispatch(getRedFlagged({
            pageNumber: 0,
            entriesPerPage: 0,
            ...alertsFilter,
            alertsdatatype: selectedValue,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        alertsFilter.startDate,
        alertsFilter.endDate,
        alertsFilter.isDisableRecords,
        alertsFilter.flags,
        alertsFilter.officer,
        alertsFilter.client,
        alertsFilter.device,
        alertsFilter.profileValue,
        dispatch,
        selectedValue,
    ]);

    useEffect(() => {
        setPageNumbers(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertsFilter])

    useEffect(() =>
    {
        if (alertsFilter?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(alertsFilter.rowsPerPage);
        }

        if (alertsFilter)
        {
            setCurrentFilter(alertsFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const prevData = React.useRef({});

    useEffect(() =>
    {
        if (alertsFilter.isGridView === true &&
            (selectedValue === TEXTMMS ||
                selectedValue === PHOTOS ||
                selectedValue === SCREENCAPTURES ||
                selectedValue === SCREENSHOTS
            ))
        {
            dispatch(changeAlertsData());
            dispatch(getalerts({
                pageNumber: 1,
                entriesPerPage: 100,
                ...alertsFilter,
                sortBy: '{"createdAt": -1}',
                alertsdatatype: selectedValue,
            }));
        }
        else
        {
            dispatch(getalerts({
                pageNumber: checkFilterChange(currentFilter, alertsFilter) ?
                    pageNumbers : 1,
                entriesPerPage,
                ...alertsFilter,
                alertwordsOnly: false,
                alertsdatatype: selectedValue,
            }));
        }
        prevData.current = selectedValue;
        setCurrentFilter(alertsFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, alertsFilter, selectedValue, pageNumbers, entriesPerPage]);

    useEffect(() =>
    {
        dispatch(resetAlertsFilterData());
        dispatch(resetaiFlagFilterData('alerts'));
        dispatch(changescreenshotGridViewFilters(true));
        dispatch(changeAlertsIdStatus());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    const getAiScanStatus = (aiScanStatus: number): React.ReactNode =>
    {
        if (aiScanStatus === 2)
        {
            return <ScoutRed />;
        }
        else if (aiScanStatus === 0)
        {
            return (
                <HourglassEmptyOutlinedIcon
                    sx={{ fill: 'lightGrey' }}
                />
            );
        }
        else
        {
            return null;
        }
    };

    const getFlagItemComponent = (data: AlertsData): React.ReactNode => (
        <DataTypeFlagItem
            getIds={
                data.dataType === DataType.SCREENSHOTS
                    ? (data) => [data.id] : undefined
            }
            dataType={data.dataType}
            alertsdata={data}
            capturedData={data}
        />
    );

    const getImageColumn = (data: AlertsData): React.ReactNode =>
        (
            <img
                height="70"
                width="80"
                alt=""
                src={data.fileUrl}
            />
        );


    const getDetailsComponent = (data: AlertsData): React.ReactNode =>
    {
        const { dataType, details, fileUrl, id, alertWords } = data;

        const renderText = (details: string): React.ReactNode => (
            <p title={details}>
                {details?.length > 250 ? (
                    <HighlightText
                        textToHighlight={details.substring(0, 250) + '...'}
                        highlightWords={alertWords || ['']}
                    />
                ) : (
                    <HighlightText
                        textToHighlight={details || ''}
                        highlightWords={alertWords || ['']}
                    />
                )}
            </p>
        );

        switch (dataType)
        {
        case 'Calls':
            if (details !== '')
            {
                const durationNumber = Number(details);
                if (!isNaN(durationNumber))
                {
                    return 'Duration: ' + secondsToHHMMSS(durationNumber);
                }
            }
            return 'Duration : 00:00:00';
        case 'SMS':
            if (details !== '')
            {
                const coordinatesRegex = /externalNumber: (.*), deviceNumber: (.*), username: (.*), message: (.*)/;
                const matches = coordinatesRegex.exec(details);
                if (matches && matches.length === 5)
                {
                    return renderText(matches[4].trim());
                }
            }
            return '';
        case 'Processes':
            if (details !== '')
            {
            const processDetails = details.split(',');
            const nameField = processDetails.find(data => data.trim().startsWith('name'));
            const name = nameField?.split(':')[1].trim();
            return name;
            }
            return '';
        case 'MMS':
        case 'Photos':
        case 'Screen Captures':
        case 'Screenshots':
            return (
                <img
                    height="50"
                    width="100"
                    src={fileUrl}
                    alt={id}
                />
            );
        default:
            return renderText(details);
        }
    };

    const openDetailColumn = (data: AlertsData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.ai'),
            size: 1,
            align: 'center' as const,
            sortByType: 'aiScanStatus',
            format: (data: AlertsData) => getAiScanStatus(data.aiScanStatus),
        },
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: AlertsData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: AlertsData) => getFlagItemComponent(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 4,
            value: (data: AlertsData) => new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: AlertsData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 2,
            value: (data: AlertsData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.data-type'),
            size: 2,
            value: (data: AlertsData) =>
            {
                const dataTypeMappings: Record<string, string> =
                {
                    Calls: 'Call History',
                    Keywords: 'Keystrokes',
                    Processes: 'Execution Time',
                    'External Media': 'External Devices',
                    Programs: 'Programs/Apps',
                    'Websites Search': 'Searches',
                };
                return dataTypeMappings[data.dataType] || data.dataType;
            },
            sortByType: 'dataType',
        },
        {
            label: t('data-type.table.details'),
            size: 4,
            align: 'center' as const,
            value: (data: AlertsData) => getDetailsComponent(data),
            sortByType: 'details',
        },
        {
            label: t('data-type.table.trigger'),
            size: 2,
            value: (data: AlertsData) => Array.isArray(data.trigger_for)
                ? data.trigger_for.join(', ')
                : data.trigger_for,
            sortByType: 'trigger_for',
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: AlertsData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: AlertsData) => openDetailColumn(data),
        },
    ];


    if (selectedValue === DataType.SMS)
    {
        columns.splice(7, 0, {
            label: t('data-type.table.image'),
            size: 2,
            align: 'center' as const,
            format: (data: AlertsData) => data?.fileUrl ? getImageColumn(data) : '',
        });
    }

    const detailItems = [
        {
            label: t('data-type.details.received-date'),
            text: (data: AlertsDetailsData) =>
                timeZoneFormat(new Date(data?.receivedDate), USER_TIME_ZONE),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: AlertsDetailsData) =>
                timeZoneFormat(new Date(data?.capturedDate), USER_TIME_ZONE),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: AlertsDetailsData) => data.device,
        },
        {
            label: t('data-type.table.data-type'),
            text: (data: AlertsDetailsData) => data.dataType,

        },
        {
            label: t('data-type.table.trigger'),
            text: (data: AlertsDetailsData) => Array.isArray(data.trigger_for)
                ? data.trigger_for.join(', ')
                : data.trigger_for,
        },
        {
            label: t('data-type.details.alert-words'),
            text: (data: AlertsDetailsData) => data?.alertWords?.join(', '),
        },
    ];

    useEffect(() =>
    {
        if (alertsData)
        {
            let recordIds;
            if (selectedValue === ALL_DATA_TYPE)
            {
                recordIds = alertsData.contents.map((item) => `${item.id}-${item.dataType}`);
            }
            else if(selectedValue === DataType.PROCESSES){
                recordIds =  alertsData.contents.map((item) =>
                    {
                            const detailsObject = Object.fromEntries(
                                item.details.split(',').map((data: string) => data.split(':').map((str: string) => str.trim()))
                            );
                            return {
                                ...item,   
                                localId: detailsObject.localId || '',
                                totalIntervalTime: detailsObject.totalIntervalTime || '',
                                name: detailsObject.name || ''
                            };
                        }
                        
                        
                )
            }
            else
            {
                recordIds = alertsData.contents.map((item) => item.id);
            }
            
            if (recordIds !== undefined)
            {
                pdfExportSetter({
                    type: selectedValue === DataType.MMS ? DataType.SMS : selectedValue,
                    recordIds,
                });
            }
            dispatch(changeTotalRecord(alertsData.numberOfEntries));
            dispatch(changeColumns(columns));
        }
    }, [alertsData, selectedValue, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<AlertsDetailsData>
                title={t('data-type.details.alerts-title')}
                open={open}
                dataType={alertsDetailsData?.dataType ? alertsDetailsData?.dataType : ''}
                data={alertsDetailsData}
                detailItems={detailItems}
                onClose={() => setOpen(false)}
                isLoading={alertsDetailsStatus === RequestStatus.InProgress}
                comments={{
                    contentId: alertsDetailsData?.id,
                    type: CommentType.ALERTS,
                }}
            >
                <LandingPageDetails {...(alertsDetailsData as AlertsDetailsData)} />
            </DataDetailsModal>


            <>
                {(selectedValue === 'MMS' || selectedValue === 'Photos'
                    || selectedValue === 'Screen Captures'
                    || selectedValue === 'Screenshots') &&
                    alertsFilter.isGridView === true &&
                    alertsGridData && alertsGridData.contents.length > 0 ?
                    (
                        <>
                            <LandingPageAlertsGrid
                                handleOpen={handleOpen}
                                selectedValue={selectedValue}
                            />
                            {
                                alertsStatus === RequestStatus.InProgress && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CircularProgress
                                            style={{
                                                width: '4rem',
                                                height: '4rem',
                                                display: 'inline-block',
                                                marginTop: '-4%',
                                            }}
                                        />
                                    </div>
                                )}
                        </>
                    ) : (
                        <>
                            {alertsFilter.isGridView === true && alertsFilter.alertwordsOnly
                                && (
                                    selectedValue === 'MMS' ||
                                    selectedValue === 'Photos' ||
                                    selectedValue === 'Screen Captures' ||
                                    selectedValue === 'Screenshots'
                                ) && (
                                <Button
                                    variant="contained"
                                    sx={{ float: 'right', top: '-25px' }}
                                    onClick={(e) =>
                                    {
                                        dispatch(changeAlertsFilter({
                                            alertwordsOnly: !alertsFilter.alertwordsOnly,
                                        }));
                                    }}
                                >
                                    {!alertsFilter.alertwordsOnly
                                        ? t('form.View-alert-words')
                                        : t('form.No-alert-words')}
                                </Button>
                            )}
                            {desktopScreen ? (
                                <DataTable<AlertsData>
                                    isLoading={alertsStatus === RequestStatus.InProgress}
                                    columns={columns}
                                    onPageChange={onPageChange}
                                    entriesPerPage={entriesPerPage}
                                    onChangeEntriesPerPage={handleEntriesPerPageChange}
                                    datas={datas}
                                    {...alertsData}
                                />
                            ) : (
                                <DataTableMobile
                                    isLoading={alertsStatus === RequestStatus.InProgress}
                                    contents={alertsData?.contents || []}
                                    setOpenModal={handleOpen}
                                    onChangeEntriesPerPage={setEntriesPerPage}
                                    entriesPerPage={entriesPerPage}
                                    onPageChange={onPageChange}
                                    numberOfEntries={alertsData?.numberOfEntries}
                                    currentPage={alertsData?.currentPage}
                                    enablePagination
                                />
                            )}
                        </>
                    )
                }
            </>
        </>
    );
}

