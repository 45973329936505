import React, { useEffect, useState } from 'react';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { Device, DevicePermissions } from 'src/models/administration/deviceData.model';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDevicePermissions,
    getDevicePermissionsIds,
    selectDevicePermissions,
    selectDevicePermissionsIds,
    selectDevicePermissionsStatus,
    selectDevicePermissionsStatusIds,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { Box, FormGroup, Grid, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { changeDateToStringDate } from 'src/utils/common';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { DataType } from 'src/models/dataType.enum';
import { useTranslation } from 'react-i18next';
import { PDFExport } from '../common-export-form/PDFExport';

export default function DevicePermissionAlerts(
    {
        open,
        onClose,
        data,
    }: Readonly<{ open: boolean; onClose: () => void; data: Device | undefined }>
): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permissions = useSelector(selectDevicePermissions);
    const permissionsStatus = useSelector(selectDevicePermissionsStatus);
    const permissionsIds = useSelector(selectDevicePermissionsIds);
    const permissionsIdsStatus = useSelector(selectDevicePermissionsStatusIds);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [entriesPerPage, setEntriesPerPage] = useState<number>(10);
    // eslint-disable-next-line max-len
    const [startDate, setStartDate] = useState<string>(changeDateToStringDate(addDays(startOfDay(new Date()), -2)));
    const [endDate, setEndDate] = useState<string>(endOfDay(new Date()).toISOString());
    const [searchValue, setSearchValue] = useState<string>('');
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);

    function onPageChange(page: number): void
    {
        setPageNumber(page);
    }

    function onEntriesPerChange(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
    }
    function onChangeDateFilter(field: string, newValue: string): void
    {

        if (field === 'startDate')
        {
            setStartDate(newValue);
        }
        else
        {
            setEndDate(newValue);
        }
    }

    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');
        dispatch(getDevicePermissionsIds({
            pageNumber,
            entriesPerPage,
            device: data?.id ?? '',
            searchValue,
            startDate,
            endDate,
        }));
    }

    useEffect(() =>
    {
        if (data?.id)
        {
            dispatch(getDevicePermissions({
                pageNumber,
                entriesPerPage,
                device: data?.id,
                searchValue,
                startDate,
                endDate,
            }));
        }
    }, [dispatch, pageNumber, entriesPerPage, data, searchValue, startDate, endDate]);

    const details = [
        {
            label: t('data-type.table.created-date'),
            text: (data: Device) => new Date(data.createdAt).toLocaleString(),
        },
        {
            label: t('data-type.details.device-alias'),
            text: (data: Device) => data.alias,
        },
        {
            label: t('data-type.table.agency-name'),
            text: (data: Device) => data.agencyName,
        },
        {
            label: t('data-type.table.client-name'),
            text: (data: Device) => data.clientName,
        },
        {
            label: t('data-type.table.platform'),
            text: (data: Device) => data.platform,
        },
        {
            label: t('data-type.table.last-connect'),
            text: (data:Device) => data.lastConnectAt ? new Date(
                data.lastConnectAt
            ).toLocaleString() : '-',
        },
    ];

    const columns: ColumnData<DevicePermissions>[] = [
        {
            label: t('data-type.table.date'),
            size: 4,
            value: (data: DevicePermissions) => new Date(data.date).toLocaleString(),
        },
        {
            label: t('data-type.table.permissions'),
            size: 4,
            value: (data: DevicePermissions) => data.permission,
        },
        {
            label: t('data-type.table.type'),
            size: 4,
            value: (data: DevicePermissions) => data.permissionType,
        },
    ];

    return (
        <DataDetailsModal<Device>
            title={t('data-type.table.device-permissions')}
            detailsTitle={t('data-type.table.permissions')}
            data={data}
            pdfEnabled={false}
            detailItems={details}
            open={open}
            onClose={()=>
                {
                    setSearchValue('');
                    onClose();
                }}
        >
            <Grid>
                <FormGroup row sx={{ marginBottom: '10px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box sx={{ padding: '5px' }}>
                                    <DateTimePicker
                                        label={t('data-type.filters.start-date')}
                                        value={startDate}
                                        onChange={(newValue) =>
                                            onChangeDateFilter('startDate', newValue as string)}
                                        renderInput={(params) => (
                                            <TextField
                                                onKeyDown={(e) => e.preventDefault()}
                                                {...params}
                                            />
                                        )}
                                    />
                                </Box>
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box sx={{ padding: '5px' }}>
                                    <DateTimePicker
                                        label={t('data-type.filters.end-date')}
                                        value={endDate}
                                        onChange={(newValue) =>
                                            onChangeDateFilter('endDate', newValue as string)}
                                        renderInput={(params) => (
                                            <TextField
                                                onKeyDown={(e) => e.preventDefault()}
                                                {...params}
                                            />
                                        )}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {permissions?.contents[0]?.id && (
                                <PDFExport
                                    selectedValue={handlerAllPDFExport}
                                    ids={permissionsIds}
                                    idStatus={permissionsIdsStatus}
                                    data={DataType.DEVICE_PERMISSIONS}
                                    formatAll={formatAll}
                                    setFormatAll={setFormatAll}
                                />
                            )}
                            <TextField
                                name="firstname"
                                label={t('data-type.table.search')}
                                autoComplete="off"
                                sx={{ marginLeft: '5px' }}
                                onChange={event => setSearchValue(event.target.value)}
                            />
                        </Box>
                    </Box>
                </FormGroup>
                <DataTable<DevicePermissions>
                    isLoading={permissionsStatus === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={onEntriesPerChange}
                    {...permissions}
                />
            </Grid>
        </DataDetailsModal>
    );
}
