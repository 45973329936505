import qs from 'qs';
import {
    OfficerFilterOptionsParams,
    ClientFilterOptionsParams,
    DeviceFilterOptionsParams,
    FilterOption,
    UpdateSeenListStatusParams,
    UpdateSeenListData,
} from 'src/models/common/filterOptionData.model';
import {
    readAPIAgencyFilterResponse,
    readAPIOfficerOrClientFilterResponse,
    readAPIDeviceFilterResponse,
    transformOfficerFilterOptionsParams,
    transformClientFilterOptionsParams,
    transformDeviceFilterOptionsParams,
    transformUpdateSeenListStatusParams,
    readUserSeenListResponse,
    readAPIDeviceVersionFilterResponse,
} from '../data-transfer-objects/common/filterOptions';
import instance from '../instance';
import { DeviceDetailsParams } from 'src/models/administration/deviceDetailsData.model';
import { transformDeviceDetailsParams } from '../data-transfer-objects/administration/deviceDetails';

export async function getAgencyFilterOptions(): Promise<FilterOption[]>
{
    const response = await instance.get('agency/no-pageable');

    return readAPIAgencyFilterResponse(response.data);
}

export async function getOfficerFilterOptions(
    params: OfficerFilterOptionsParams
): Promise<FilterOption[]>
{
    const response = await instance.get(
        'users/no-pageable/officers',
        { params: transformOfficerFilterOptionsParams(params) }
    );

    return readAPIOfficerOrClientFilterResponse(response.data);
}

export async function getClientFilterOptions(
    params: ClientFilterOptionsParams
): Promise<FilterOption[]>
{
    const response = await instance.get(
        'clients/no-pageable',
        { params: transformClientFilterOptionsParams(params) }
    );

    return readAPIOfficerOrClientFilterResponse(response.data);
}

export async function getDeviceFilterOptions(
    params: DeviceFilterOptionsParams
): Promise<FilterOption[]>
{
    const response = await instance.get(
        'device/no-pageable',
        { params: transformDeviceFilterOptionsParams(params) }
    );

    return readAPIDeviceFilterResponse(response.data);
}

export async function getVersionsFilterOptions(
    params: DeviceDetailsParams
): Promise<FilterOption[]>
{
    const response = await instance.get(
        'device/version/no-pageable',
        { params: transformDeviceDetailsParams(params) }
    );

    return readAPIDeviceVersionFilterResponse(response.data);
}

export async function getDeviceOptionsById(deviceIds: string[]): Promise<FilterOption[]>
{
    const response = await instance.get(
        'device/no-pageable/by-ids',
        {
            params: { ids: deviceIds },
            paramsSerializer: params =>
            {
                return qs.stringify(params, { arrayFormat: 'comma' });
            },
        }
    );

    return readAPIDeviceFilterResponse(response.data);
}
export async function updateSeenListStatusRequest(
    params : UpdateSeenListStatusParams
): Promise<UpdateSeenListData>
{
    const response = await instance.patch(
        `alerts/seen/${params.detailsId}`,
        transformUpdateSeenListStatusParams(params)
    );
    return readUserSeenListResponse(response.data);
}
