import {
    createAsyncThunk,
    createSlice,
    createSelector,
    PayloadAction,
} from '@reduxjs/toolkit';
import { endOfDay, addDays, startOfDay } from 'date-fns';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    CaptureFilters, CaptureParams, GetAlertsId, ReportsFilters,
} from 'src/models/captures/capturedData.model';
import { AssignedOfficerSessionRequest } from 'src/models/administration/assignedOfficerData.model';

import { RequestStatus } from 'src/models/requestStatus.enum';
import { RootState } from '../store';

import {
    getKeystrokes as getKeystrokesRequest,
    getKeystrokeDetails as getKeystrokeDetailsRequest,
} from 'src/services/captures/keystrokes';
import {
    getScreenshots as getScreenshotsRequest,
    getScreenshotsMeta as getScreenshotsMetaRequest,
    getScreenshotsdeviceid as getScreenshotsdeviceidRequest,
    getScreenshotsMetadevicebyid as getScreenshotsMetadevicebyidRequest,
} from 'src/services/captures/screenshot';
import {
    getScreenCaptures as getScreenCapturesRequest,
    getScreenCapturesIds as getScreenCapturesIdsRequest,
}
    from 'src/services/captures/screenCapture';
import {
    getPhotos as getPhotosRequest,
    getPhotosExportIds as getPhotosIdsRequest,
} from 'src/services/captures/photos';
import {
    getTextSMSs as getTextSMSsRequest,
    getTextSMSsForDetails as getTextSMSsForDetailsRequest,
    getSMSIds,
} from 'src/services/captures/textSMS';

import { getTextMMSIds, getTextMMSs as getTextMMSsRequest } from 'src/services/captures/textMMS';

import {
    getExternalDevices as getExternalDevicesRequest,
    getExternalDevice,
} from 'src/services/captures/externalDevices';
import { FilesTrackingData } from 'src/models/captures/filesTrackingData.models';
import {
    getFilesTracking as getFilesTrackingRequest,
    getFileTracking,
} from 'src/services/captures/filesTracking';
import {
    getInstalledPrograms as getInstalledProgramsRequest,
    getInstalledProgramsByDevice as getInstalledProgramsByDeviceRequest,
    getAppsCompareDetails as getAppsCompareDetailsRequest,
} from 'src/services/captures/installedPrograms';
import {
    getGeolocations as getGeolocationsRequest,
    getGeolocationsByDate as getGeolocationsByDateRequest,
    getGeolocationslistDevice as getGeolocationslistDeviceRequest,
} from 'src/services/captures/geolocation';
import {
    getEmails as getEmailsRequest,
} from 'src/services/captures/emails';
import { getFilesInfo as getEmailAttachmentsRequest } from 'src/services/files/getFilesInfo';
import {
    getLogonEvents as getLogonEventsRequest,
} from 'src/services/captures/logonEvents';
import {
    getCallHistory as getCallHistoryRequest,
    getCallHistoryById,
} from 'src/services/captures/callHistory';
import {
    getPrintedDocuments as getPrintedDocumentsRequest,
} from 'src/services/captures/printedDocuments';
import {
    getProcesses as getProcessesRequest,
    getProcess,
} from 'src/services/captures/processes';
import {
    getSearches as getSearchesRequest,
    getSearch,
} from 'src/services/captures/searches';
import {
    getWebsites as getWebsitesRequest,
    getWebsite,
} from 'src/services/captures/websites';
import {
    getAgencyFilterOptions as getAgencyFilterOptionsRequest,
    getOfficerFilterOptions as getOfficerFilterOptionsRequest,
    getClientFilterOptions as getClientFilterOptionsRequest,
    getDeviceFilterOptions as getDeviceFilterOptionsRequest,
    getVersionsFilterOptions as getversionsFilterOptionsRequest,
    getDeviceOptionsById as getDeviceOptionsByIdRequest,
    updateSeenListStatusRequest,
} from 'src/services/common/filters';
import { updateFlag as updateFlagRequest } from 'src/services/captures/flags';
import { updateFlagToAll as updateFlagAllRequest } from 'src/services/captures/flags';
import { KeystrokeData, KeystrokeDetailsData } from 'src/models/captures/keystrokeData.model';
import { ScreenshotData, ScreenshotDetailsData } from 'src/models/captures/screenshotData.model';
import { ScreenCaptureData } from 'src/models/captures/screenCaptureData.model';
import { PhotoData } from 'src/models/captures/photoData.model';
import {
    TextSMSData,
    TextSMSForDetails,
} from 'src/models/captures/textSMSData.model';
import { TextMMSData } from 'src/models/captures/textMMSData.model';
import { ProgramData, InstalledProgramsData }
    from 'src/models/captures/installedProgramsData.model';
import { ExternalDeviceData } from 'src/models/captures/externalDeviceData.model';
import { GeolocationData } from 'src/models/captures/geolocationData.model';
import { EmailData } from 'src/models/captures/emailData.model';
import { FileInfo } from 'src/models/captures/fileInfo.model';
import { LogonEventData } from 'src/models/captures/logonEventData.model';
import { CallHistoryData } from 'src/models/captures/callHistoryData.model';
import { PrintedDocumentData } from 'src/models/captures/printedDocumentData.model';
import { ProcessData } from 'src/models/captures/processData.model';
import { SearchData } from 'src/models/captures/searchData.model';
import { WebsiteData } from 'src/models/captures/websiteData.model';
import {
    OfficerFilterOptionsParams,
    ClientFilterOptionsParams,
    DeviceFilterOptionsParams,
    FilterOption,
    AlertsDetailsParams,
    UpdateSeenStatusParams,
    UpdateSeenListStatusParams,
    DeviceRecordListParams,
    RecordIdsListParams,
} from 'src/models/common/filterOptionData.model';
import { Flag, FlagWithAI } from 'src/models/flag.enum';
import { Keys, read, write } from 'src/utils/storage';
import { FlagParams } from 'src/models/captures/flagData.model';
import { changeDateToStringDate } from 'src/utils/common';

import {
    getAlertsRequest,
    getActiveDevicesCountRequest,
    getInActiveDevicesCountRequest,
    getRecordCountRequest,
    getRedFlaggedCountRequest,
    getAlertsDetails as getAlertsDetailsRequest,
    updateSeenStatusRequest,
    getalertsIds,
    getActiveDevicesIdRequest,
    getInActiveDevicesIdRequest,
    getallInActiveDevicesCountRequest,
    getAllActiveDevicesCountRequest,
} from 'src/services/captures/alerts';

import {
    CountData,
    AlertsData,
    AlertsDetailsData,
    UpdateSeenData,
    GetSeenData,
    InActiveData,
    ActiveData,
    AllActiveData,
} from 'src/models/captures/alertsData.model';
import { GenerateReportParamsIds } from 'src/models/captures/report.model';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
import { DataType } from 'src/models/dataType.enum';
import { 
    getAllToExportData as getAllToExportDataRequest 
} from 'src/services/administration/reports';
import { ColumnData } from 'src/components/data-table/DataTable';
import { DeviceDetailsParams } from 'src/models/administration/deviceDetailsData.model';
import { Platform } from 'src/models/platforms.enum';

type ExportRecordData = 
CallHistoryData | 
KeystrokeData | 
FilesTrackingData |
GeolocationData |
ExternalDeviceData |
ProcessData |
PrintedDocumentData |
EmailData |
ScreenCaptureData |
ScreenshotData |
InstalledProgramsData |
PhotoData |
LogonEventData |
TextMMSData |
TextSMSData |
SearchData |
WebsiteData
  

export interface CapturesState {
    filters: CaptureFilters;
    reportsFilter:ReportsFilters;
    columnValue: ColumnData<DataType>[];
    tableTotalCount?:number
    tableDatatype?:DataType,
    alertsFilter: CaptureFilters
    sessionFilters: AssignedOfficerSessionRequest
    aiFlagFilter?: string[]
    screenshotGridView?: boolean
    officerFilterOptions?: FilterOption[]
    officerFilterOptionsStatus: RequestStatus
    agencyFilterOptions?: FilterOption[]
    agencyFilterOptionsStatus: RequestStatus
    clientFilterOptions?: FilterOption[]
    clientFilterOptionsStatus: RequestStatus
    deviceFilterOptions?: FilterOption[]
    deviceFilterOptionsStatus: RequestStatus
    deviceVersionFilterOptions?: FilterOption[]
    deviceVersionFilterOptionsStatus: RequestStatus
    deviceFilterOptionsById?: FilterOption[]
    deviceFilterOptionsByIdStatus: RequestStatus
    keystrokes?: PaginatedContent<KeystrokeData>
    keystrokesStatus: RequestStatus
    keystrokeDetails?: KeystrokeDetailsData
    keystrokeDetailsStatus: RequestStatus
    screenshot?: ScreenshotData
    screenshotdeviceid?: ScreenshotData
    screenshotModalData?: ScreenshotDetailsData
    screenshots?: PaginatedContent<ScreenshotData>
    screenshotsMeta?: PaginatedContent<ScreenshotData>
    screenshotsdeviceid?: PaginatedContent<ScreenshotData>
    // eslint-disable-next-line
    screenshotsdevicebyid?: any;
    screenshotsStatus: RequestStatus;
    screenCaptures?: PaginatedContent<ScreenCaptureData>;
    screenCapture?: ScreenCaptureData;
    screenCapturesStatus: RequestStatus;
    photos?: PaginatedContent<PhotoData>;
    photosStatus: RequestStatus;
    photo?: PhotoData;
    textSMSs?: PaginatedContent<TextSMSData>;
    textSMS?: TextSMSData;
    textSMSsStatus: RequestStatus;
    textSMSsForDetails?: PaginatedContent<TextSMSData>;
    textSMSsForDetailsStatus: RequestStatus;
    textMMSs?: PaginatedContent<TextMMSData>;
    textMMS?: TextMMSData;
    textMMSsStatus: RequestStatus;
    textMMSsForDetails?: PaginatedContent<TextMMSData>;
    textMMSsForDetailsStatus: RequestStatus;
    externalDevices?: PaginatedContent<ExternalDeviceData>;
    externalDevicesStatus: RequestStatus;
    externalDeviceDetails?: ExternalDeviceData;
    externalDeviceDetailsStatus: RequestStatus;
    filesTracking?: PaginatedContent<FilesTrackingData>;
    filesTrackingStatus: RequestStatus;
    fileTrackingDetails?: FilesTrackingData;
    fileTrackingDetailsStatus: RequestStatus;
    appscompare?: ProgramData[];
    appscompareStatus: RequestStatus;
    installedPrograms?: PaginatedContent<InstalledProgramsData>;
    installedProgramsStatus: RequestStatus;
    installedProgramsByDevice?: InstalledProgramsData[],
    installedProgramsByDeviceStatus: RequestStatus,
    installedProgramsDetails?: InstalledProgramsData;
    geolocation?: GeolocationData;
    geolocationFilter?:CaptureParams;
    geolocations?: PaginatedContent<GeolocationData>;
    geolocationsStatus: RequestStatus;
    geolocationsByDate?: GeolocationData[];
    geolocationslistDevice?: PaginatedContent<GeolocationData>
    geolocationlistDevice?: GeolocationData
    geolocationsByDateStatus: RequestStatus;
    geolocationslistDeviceStatus: RequestStatus
    emails?: PaginatedContent<EmailData>;
    email?: EmailData;
    emailsStatus: RequestStatus;
    emailAttachments: FileInfo[];
    filterflagValues?: string[];
    emailAttachmentsStatus: RequestStatus;
    logonEvent?: LogonEventData;
    logonEvents?: PaginatedContent<LogonEventData>;
    logonEventsStatus: RequestStatus;
    callHistory?: PaginatedContent<CallHistoryData>;
    ExportDataAllRecord?: PaginatedContent<ExportRecordData>;
    callHistoryStatus: RequestStatus;
    ExportDataAllRecordStatus?: RequestStatus
    callHistoryIdStatus?: RequestStatus
    callHistoryDetails?: CallHistoryData
    callHistoryDetailsStatus: RequestStatus
    alerts?: PaginatedContent<AlertsData>
    alertsGridData: PaginatedContent<AlertsData>
    alertsFilterData?: string[]
    alertsStatus: RequestStatus
    activedata?: PaginatedContent<ActiveData>
    allActivedata?: PaginatedContent<AllActiveData>
    activedataStatus?: RequestStatus
    allActivedataStatus?: RequestStatus
    inactivedata?: PaginatedContent<InActiveData>
    inactivedataStatus?: RequestStatus
    allinactivedata?: PaginatedContent<InActiveData>
    allinactivedataStatus?: RequestStatus
    // eslint-disable-next-line
    cardDataIds?: any;
    cardDataStatus?: RequestStatus;
    activedatasearchValue?: string;
    activedataPlatformValue?: Platform;
    inactivedatasearchValue?: string;
    redflagcount?: CountData;
    redflagcountStatus?: RequestStatus;
    recordcount?: CountData;
    recordcountStatus?: RequestStatus;
    alertsDetails?: AlertsDetailsData;
    alertsDetailsStatus: RequestStatus;
    getSeenDetails?: GetSeenData;
    updateSeenDetails?: UpdateSeenData;
    updateSeenDetailsStatus: RequestStatus;
    // eslint-disable-next-line
    SMSIds?: any;
    SMSIdstatus: RequestStatus;
    // eslint-disable-next-line
    AlertsIds?: any;
    AlertsIdstatus?: RequestStatus;
    // eslint-disable-next-line
    AlertsJPEG?: any;
    AlertsJPEGstatus?: RequestStatus;

    // eslint-disable-next-line
    ViewDataIds?: any;
    ViewDataIdstatus?: RequestStatus;

    // eslint-disable-next-line
    ViewDataJPEG?: any;
    ViewDataJPEGstatus?: RequestStatus;

    printedDocumentDetails?: PrintedDocumentData;
    printedDocuments?: PaginatedContent<PrintedDocumentData>;
    printedDocumentsStatus: RequestStatus;
    processes?: PaginatedContent<ProcessData>;
    processesStatus: RequestStatus;
    processDetails?: ProcessData;
    processDetailsStatus: RequestStatus;
    search?: SearchData;
    searches?: PaginatedContent<SearchData>;
    searchesStatus: RequestStatus;
    searchDetails?: SearchData;
    searchDetailsStatus: RequestStatus;
    website?: WebsiteData;
    websites?: PaginatedContent<WebsiteData>;
    websitesStatus: RequestStatus;
    websiteDetails?: WebsiteData;
    websiteDetailsStatus: RequestStatus;
    updatedFlag?: unknown;
    updatedFlagToAll?: object;
    updateFlagStatus: RequestStatus;
    updateFlagAllStatus: RequestStatus;
    allPageData: Record<number, unknown>;
}
const isAlertsPage = window.location.pathname === ALERTSPAGE;

const initialState: CapturesState = {
    filters: read<CaptureFilters>(Keys.Filters) ?? {
        startDate: changeDateToStringDate(startOfDay(new Date())),
        endDate: endOfDay(new Date()).toISOString(),
        flags: Object.values(Flag).map((flag) => flag),
        searchValue: '',
        sortBy: '{"createdAt": -1}',
        isDateFilterChanged: false,
        isDisableRecords: false,
        isGridView: false,
        alertwordsOnly: false,
    },
    reportsFilter: read<ReportsFilters>(Keys.reportsFilter) ?? {
        startDate:'',
        endDate: '',
    },
    screenshotGridView: read(Keys.screenshotGridView) ?? true,

    alertsGridData: {
        contents: [],
        currentPage: 1,
        numberOfEntries: 0,
    },

    sessionFilters: read<AssignedOfficerSessionRequest>(Keys.SessionFilters) ?? {
        startDate: changeDateToStringDate(addDays(startOfDay(new Date()), -2)),
        endDate: endOfDay(new Date()).toISOString(),
        searchValue: '',
        sortBy: '{"createdAt": -1}',
    },

    alertsFilter: read<CaptureFilters>(Keys.AlertsFilter) ?? {
        startDate: changeDateToStringDate(startOfDay(new Date())),
        endDate: endOfDay(new Date()).toISOString(),
        flags: Object.values(Flag).map((flag) =>
        {
            if (flag === FlagWithAI.NO_FLAG)
            {
                return null;
            }
            return flag;
        }).filter(Boolean) as string[],
        searchValue: '',
        sortBy: '{"createdAt": -1}',
        isDateFilterChanged: false,
        isDisableRecords: false,
        isGridView: false,
        alertwordsOnly: false,
        alertsdatatype: "Keywords",
    },
    aiFlagFilter: read<CaptureFilters>(Keys.Filters)?.flags ??
        Object.values(FlagWithAI).map((flag) =>
        {
            if (isAlertsPage && flag === FlagWithAI.NO_FLAG)
            {
                return null;
            }
            return flag;
        }).filter(Boolean) as string[],
    officerFilterOptionsStatus: RequestStatus.NotStarted,
    agencyFilterOptionsStatus: RequestStatus.NotStarted,
    clientFilterOptionsStatus: RequestStatus.NotStarted,
    deviceFilterOptionsStatus: RequestStatus.NotStarted,
    deviceVersionFilterOptionsStatus: RequestStatus.NotStarted,
    deviceFilterOptionsByIdStatus: RequestStatus.NotStarted,
    keystrokesStatus: RequestStatus.NotStarted,
    keystrokeDetailsStatus: RequestStatus.NotStarted,
    screenshotsStatus: RequestStatus.NotStarted,
    screenCapturesStatus: RequestStatus.NotStarted,
    photosStatus: RequestStatus.NotStarted,
    textSMSsStatus: RequestStatus.NotStarted,
    textSMSsForDetailsStatus: RequestStatus.NotStarted,
    externalDevicesStatus: RequestStatus.NotStarted,
    externalDeviceDetailsStatus: RequestStatus.NotStarted,
    filesTrackingStatus: RequestStatus.NotStarted,
    fileTrackingDetailsStatus: RequestStatus.NotStarted,
    installedProgramsStatus: RequestStatus.NotStarted,
    installedProgramsByDeviceStatus: RequestStatus.NotStarted,
    geolocationsStatus: RequestStatus.NotStarted,
    geolocationsByDateStatus: RequestStatus.NotStarted,
    emailsStatus: RequestStatus.NotStarted,
    emailAttachmentsStatus: RequestStatus.NotStarted,
    emailAttachments: [],
    filterflagValues: [],
    alertsFilterData: [],
    columnValue:[],
    logonEventsStatus: RequestStatus.NotStarted,
    callHistoryIdStatus: RequestStatus.NotStarted,
    callHistoryStatus: RequestStatus.NotStarted,
    ExportDataAllRecordStatus: RequestStatus.NotStarted,
    callHistoryDetailsStatus: RequestStatus.NotStarted,
    SMSIdstatus: RequestStatus.NotStarted,
    AlertsIdstatus: RequestStatus.NotStarted,
    AlertsJPEGstatus: RequestStatus.NotStarted,

    alertsStatus: RequestStatus.NotStarted,
    alertsDetailsStatus: RequestStatus.NotStarted,
    updateSeenDetailsStatus: RequestStatus.NotStarted,

    printedDocumentsStatus: RequestStatus.NotStarted,
    processesStatus: RequestStatus.NotStarted,
    processDetailsStatus: RequestStatus.NotStarted,
    searchesStatus: RequestStatus.NotStarted,
    searchDetailsStatus: RequestStatus.NotStarted,
    websitesStatus: RequestStatus.NotStarted,
    websiteDetailsStatus: RequestStatus.NotStarted,
    updateFlagStatus: RequestStatus.NotStarted,
    updateFlagAllStatus: RequestStatus.NotStarted,
    textMMSsStatus: RequestStatus.NotStarted,
    textMMSsForDetailsStatus: RequestStatus.NotStarted,
    geolocationslistDeviceStatus: RequestStatus.NotStarted,
    appscompareStatus: RequestStatus.NotStarted,
    allPageData: {},

};
export const getalerts = createAsyncThunk(
    'captures/alerts',
    async (params: CaptureParams) => getAlertsRequest(params)
);

export const getalertsIdsDetails = createAsyncThunk(
    'captures/alertsids',
    async (params: GetAlertsId) => getalertsIds(params)
);

export const getalertsJPEGDetails = createAsyncThunk(
    'captures/alertsJPEG',
    async (params: GetAlertsId) => getalertsIds(params)
);

export const getAlertsDetails = createAsyncThunk(
    'captures/alertsDetails',
    async (params: AlertsDetailsParams) => getAlertsDetailsRequest(params)
);

export const updateSeenStatus = createAsyncThunk(
    'captures/updateseenstatusDetails',
    async (params: UpdateSeenStatusParams) => updateSeenStatusRequest(params)
);

export const updateSeenListStatus = createAsyncThunk(
    'captures/updateseenliststatusDetails',
    async (params: UpdateSeenListStatusParams) => updateSeenListStatusRequest(params)
);

export const getActiveDevices = createAsyncThunk(
    'captures/activedevices',
    async (params: DeviceRecordListParams) => getActiveDevicesCountRequest(params)
);
export const getAllActiveDevices = createAsyncThunk(
    'captures/allactivedevices',
    async (params: DeviceRecordListParams) => getAllActiveDevicesCountRequest(params)
);

export const getActiveDevicesId = createAsyncThunk(
    'captures/activedevicesid',
    async (params: RecordIdsListParams) => getActiveDevicesIdRequest(params)
);

export const getInActiveDevices = createAsyncThunk(
    'captures/inactivedevices',
    async (params: DeviceRecordListParams) => getInActiveDevicesCountRequest(params)
);

export const getallInActiveDevices = createAsyncThunk(
    'captures/inactivedevicesxlsx',
    async (params: DeviceRecordListParams) => getallInActiveDevicesCountRequest(params)
);

export const getInActiveDevicesId = createAsyncThunk(
    'captures/inactivedevicesid',
    async (params: RecordIdsListParams) => getInActiveDevicesIdRequest(params)
);

export const getRedFlagged = createAsyncThunk(
    'captures/flag',
    async (params: CaptureParams) => getRedFlaggedCountRequest(params)
);

export const getRecord = createAsyncThunk(
    'captures/record',
    async () => getRecordCountRequest()
);


export const getAgencyFilterOptions = createAsyncThunk(
    'common/agencyFilters',
    async () => getAgencyFilterOptionsRequest()
);

export const getOfficerFilterOptions = createAsyncThunk(
    'common/officerFilters',
    async (params: OfficerFilterOptionsParams) => getOfficerFilterOptionsRequest(params)
);

export const getClientFilterOptions = createAsyncThunk(
    'common/clientFilters',
    async (params: ClientFilterOptionsParams) => getClientFilterOptionsRequest(params)
);

export const getDeviceFilterOptions = createAsyncThunk(
    'common/deviceFilters',
    async (params: DeviceFilterOptionsParams) => getDeviceFilterOptionsRequest(params)
);

export const getVersionsFilterOptions = createAsyncThunk(
    'common/versionFilters',
    async (params: DeviceDetailsParams) => getversionsFilterOptionsRequest(params)
);


export const getDeviceFilterOptionsById = createAsyncThunk(
    'common/devices-by-id',
    async (params: string[]) => getDeviceOptionsByIdRequest(params)
);

export const getKeystrokes = createAsyncThunk(
    'captures/keystrokes',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getKeystrokesRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getKeystrokeDetails = createAsyncThunk(
    'captures/keystrokeDetails',
    async (keystrokeId: string) => getKeystrokeDetailsRequest(keystrokeId)
);

export const getScreenshots = createAsyncThunk(
    'captures/screenshots',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getScreenshotsRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);
export const getScreenshotsMeta = createAsyncThunk(
    'captures/screenshotsMeta',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getScreenshotsMetaRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getScreenshotsdeviceid = createAsyncThunk(
    'captures/screenshotsdeviceid',
    async (params: CaptureParams) =>
    {
        return getScreenshotsdeviceidRequest(params);
    }
);
export const getScreenshotsMetadevicebyid = createAsyncThunk(
    'captures/screenshotsmetabydeviceid',
    async (params: CaptureParams) =>
    {
        return getScreenshotsMetadevicebyidRequest(params);
    }
);
export const getScreenCaptures = createAsyncThunk(
    'captures/screen-captures',
    async (params: CaptureParams) =>
        getScreenCapturesRequest(params)
);

export const getScreenCapturesIDS = createAsyncThunk(
    'captures/screen-capturesIDs',
    async (params: GetAlertsId) =>
        getScreenCapturesIdsRequest(params)
);

export const getScreenCapturesJPEG = createAsyncThunk(
    'captures/screen-capturesJPEG',
    async (params: GetAlertsId) =>
        getScreenCapturesIdsRequest(params)
);

export const getPhotos = createAsyncThunk(
    'captures/photos',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getPhotosRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getPhotosIDS = createAsyncThunk(
    'captures/photosIDs',
    async (params: GetAlertsId) =>
        getPhotosIdsRequest(params)
);

export const getPhotosJPEG = createAsyncThunk(
    'captures/photosJPEG',
    async (params: GetAlertsId) =>
        getPhotosIdsRequest(params)
);

export const getTextSMSs = createAsyncThunk(
    'captures/text-sms',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getTextSMSsRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }

);

export const getTextSMSsForDetails = createAsyncThunk(
    'captures/text-sms-for-details',
    async (params: TextSMSForDetails) => getTextSMSsForDetailsRequest(params)
);



export const getTextMMSs = createAsyncThunk(
    'captures/text-mms',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getTextMMSsRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getTextMMSIDS = createAsyncThunk(
    'captures/text-mmsIds',
    async (params: GetAlertsId) => getTextMMSIds(params)
);

export const getTextMMSJPEG = createAsyncThunk(
    'captures/text-mmsJPEG',
    async (params: GetAlertsId) => getTextMMSIds(params)
);

export const getExternalDevices = createAsyncThunk(
    'captures/external-devices',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getExternalDevicesRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getExternalDeviceDetails = createAsyncThunk(
    'captures/externalDeviceDetails',
    async (externalDeviceId: string) => getExternalDevice(externalDeviceId)
);

export const getFilesTracking = createAsyncThunk(
    'captures/files-tracking',
    async (params: CaptureParams) =>
        getFilesTrackingRequest(params)
);

export const getFileTrackingDetails = createAsyncThunk(
    'captures/filetrackingDetails',
    async (filetrackingId: string) => getFileTracking(filetrackingId)
);

export const getInstalledPrograms = createAsyncThunk(
    'captures/installed-programs',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getInstalledProgramsRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getInstalledProgramsByDevice = createAsyncThunk(
    'captures/installed-programs-by-device',
    async ({
        deviceId,
        agencyId,
        clientId,
        officerId,
        isownConfig,
        defaultPrograms,
    }: {
        deviceId?: string
        agencyId?: string
        clientId?: string
        officerId?: string
        defaultPrograms?: boolean,
        isownConfig?:boolean,
    }) =>
        getInstalledProgramsByDeviceRequest({
            deviceId,
            agencyId,
            clientId,
            officerId,
            defaultPrograms,
            isownConfig,
        })
);

export const getGeolocations = createAsyncThunk(
    'captures/geolocations',
    async (params: CaptureParams) => getGeolocationsRequest(params)
);
export const getGeolocationslistDevice = createAsyncThunk(
    'captures/geolocations-list-device',
    async (params: CaptureParams) =>
    {
        return getGeolocationslistDeviceRequest(params);
    }
);
export const getGeolocationsByDate = createAsyncThunk(
    'captures/geolocations-by-date',
    async (params: { date: Date, deviceId: string }) =>
        getGeolocationsByDateRequest(params)
);

export const getEmails = createAsyncThunk(
    'captures/emails',
    async (params: CaptureParams) =>
        getEmailsRequest(params)
);

export const getEmailAttachments = createAsyncThunk(
    'captures/email-attachments',
    async (params: string[]) => getEmailAttachmentsRequest(params)
);

export const getLogonEvents = createAsyncThunk(
    'captures/logon-events',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getLogonEventsRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getCallHistory = createAsyncThunk(
    'captures/call-history',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getCallHistoryRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);
export const getAllToExportDatas = createAsyncThunk(
    'captures/call-history/allrecords',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getAllToExportDataRequest(params);
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);
export const getCallHistoryDetails = createAsyncThunk(
    'captures/callHistoryDetails',
    async (id: string) => getCallHistoryById(id)
);

export const getPrintedDocuments = createAsyncThunk(
    'captures/printed-documents',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getPrintedDocumentsRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getProcesses = createAsyncThunk(
    'captures/process',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getProcessesRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getProcessDetails = createAsyncThunk(
    'captures/processDetails',
    async (processId: string) => getProcess(processId)
);

export const getSearches = createAsyncThunk(
    'captures/search',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getSearchesRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getSearchDetails = createAsyncThunk(
    'captures/searchDetails',
    async (searchId: string) => getSearch(searchId)
);

export const getWebsites = createAsyncThunk(
    'captures/website',
    async (params: CaptureParams, { dispatch }) =>
    {
        try
        {
            const response = await getWebsitesRequest(params);
            dispatch(changeAllPageData({ page: response.currentPage, data: response.contents }));
            return response;
        }
        catch (err)
        {
            dispatch(changeAllPageData(undefined));
        }
    }
);

export const getWebsiteDetails = createAsyncThunk(
    'captures/websiteDetails',
    async (websiteId: string) => getWebsite(websiteId)
);

export const updateFlag = createAsyncThunk(
    'captures/update-flag',
    async (params: FlagParams) =>
        updateFlagRequest(params)
);
export const updateFlagToAll = createAsyncThunk(
    'captures/update-flag-all',
    async (params: FlagParams) =>
        updateFlagAllRequest(params)
);

export const getAppsCompareDetails = createAsyncThunk(
    'captures/apps-compareDetails',
    async (recordId: string) => getAppsCompareDetailsRequest(recordId)
);

export const getSMSIdDetails = createAsyncThunk(
    'captures/smsids',
    async (recordId: GenerateReportParamsIds) => getSMSIds(recordId)
);

export const capturesSlice = createSlice({
    name: 'captures',
    initialState,
    reducers: {
        clearAgencyFilterOptions: (state) =>
        {
            state.agencyFilterOptions = undefined;
            state.agencyFilterOptionsStatus = RequestStatus.NotStarted;
        },
        clearClientFilterOptions: (state) =>
        {
            state.clientFilterOptions = undefined;
            state.clientFilterOptionsStatus = RequestStatus.NotStarted;
        },
        clearDeviceFilterOptions: (state) =>
        {
            state.deviceFilterOptions = undefined;
            state.deviceFilterOptionsStatus = RequestStatus.NotStarted;
        },
        clearDeviceFilterOptionsById: (state) =>
        {
            state.deviceFilterOptionsById = undefined;
            state.deviceFilterOptionsByIdStatus = RequestStatus.NotStarted;
        },
        changeFilters: (state, action: PayloadAction<CaptureFilters>) =>
        {
            state.filters = { ...state.filters, ...action.payload };
            write(Keys.Filters, state.filters);
        },
        changeColumns: (state, action) =>
        {
            state.columnValue = action.payload;
        },
        changeTotalRecord: (state, action) =>
        {
            state.tableTotalCount = action.payload;
        },
        changeTableDataType: (state, action) =>
        {
            state.tableDatatype = action.payload;
        },
        changescreenshotGridViewFilters: (state, action) =>
        {
            state.screenshotGridView = action.payload;
            write(Keys.screenshotGridView, state.screenshotGridView);
        },
        changeDateCheckedFilters: (state, action: PayloadAction<CaptureFilters>) =>
        {
            state.filters = { ...state.filters, ...action.payload };
            write(Keys.Filters, state.filters);
        },
        changeSessionFilters: (state, action: PayloadAction<AssignedOfficerSessionRequest>) =>
        {
            state.sessionFilters = { ...state.sessionFilters, ...action.payload };
            write(Keys.SessionFilters, state.sessionFilters);
        },
        changeReportFilters: (state, action: PayloadAction<ReportsFilters>) =>
        {
            state.reportsFilter = { ...state.reportsFilter, ...action.payload };
            write(Keys.reportsFilter, state.reportsFilter);
        },
        changeAlertsFilter: (state, action: PayloadAction<CaptureFilters>) =>
        {
            state.alertsFilter = { ...state.alertsFilter, ...action.payload };
            write(Keys.AlertsFilter, state.alertsFilter);
        },
        changeDateCheckedAlertsFilters: (state, action: PayloadAction<CaptureFilters>) =>
        {
            state.alertsFilter = { ...state.alertsFilter, ...action.payload };
            write(Keys.AlertsFilter, state.alertsFilter);
        },
        changeAgencyFilter: (state, action: PayloadAction<string>) =>
        {
            state.filters = {
                ...state.filters,
                agency: action.payload,
                officer: undefined,
                client: undefined,
                device: undefined,
                user: undefined,
            };
            write(Keys.Filters, state.filters);
        },
        changeOfficerFilter: (state, action: PayloadAction<string>) =>
        {
            state.filters = {
                ...state.filters,
                officer: action.payload,
                client: undefined,
                device: undefined,
            };
            write(Keys.Filters, state.filters);
        },
        changeClientFilter: (state, action: PayloadAction<string>) =>
        {
            state.filters = {
                ...state.filters,
                client: action.payload,
                device: undefined,
            };
            write(Keys.Filters, state.filters);
        },
        changeAgencyAlertsFilter: (state, action: PayloadAction<string>) =>
        {
            state.alertsFilter = {
                ...state.alertsFilter,
                agency: action.payload,
                officer: undefined,
                client: undefined,
                device: undefined,
                user: undefined,
            };
            write(Keys.AlertsFilter, state.alertsFilter);
        },
        changeOfficerAlertsFilter: (state, action: PayloadAction<string>) =>
        {
            state.alertsFilter = {
                ...state.alertsFilter,
                officer: action.payload,
                client: undefined,
                device: undefined,
            };
            write(Keys.AlertsFilter, state.alertsFilter);
        },
        changeClientAlertsFilter: (state, action: PayloadAction<string>) =>
        {
            state.alertsFilter = {
                ...state.alertsFilter,
                client: action.payload,
                device: undefined,
            };
            write(Keys.AlertsFilter, state.alertsFilter);
        },
        changeAlertsFlag: (state, action) =>
        {
            const { id, flagType, flag } = action.payload;
            state.alerts?.contents?.forEach(item =>
            {
                if (item.id === id)
                {
                    item.flag = flagType;
                    if (flagType === Flag.NO_FLAG)
                    {
                        item.trigger_for = item.trigger_for.filter(value => value !== flag);
                    }
                    else if (!item.trigger_for.includes(flag))
                    {
                        item.trigger_for.push(flag);

                    }
                }
            });
        },

        changeRedFlagCount: (state, action) =>
        {
            state.redflagcount = action.payload;

        },
        changeLogonEvent: (state, action: PayloadAction<LogonEventData>) =>
        {
            state.logonEvent = action.payload;
        },
        changeScreenshot: (state, action: PayloadAction<ScreenshotData | undefined>) =>
        {
            state.screenshot = action.payload ?? undefined;
        },
        changeScreenshotDetailsData: (state, action) =>
        {
            state.screenshotModalData = action.payload ?? undefined;
        },
        changeScreenshotDetailsFilter: (state, action) =>
        {
            state.screenshotModalData = {...state.screenshotModalData,...action.payload };
        },
        changeScreenshotDeviceId: (state, action: PayloadAction<ScreenshotData | undefined>) =>
        {
            state.screenshotdeviceid = action.payload ?? undefined;
        },
        changeScreenCapture: (state, action: PayloadAction<ScreenCaptureData>) =>
        {
            state.screenCapture = action.payload;
        },
        changePhoto: (state, action: PayloadAction<PhotoData>) =>
        {
            state.photo = action.payload;
        },
        changeEmail: (state, action: PayloadAction<EmailData>) =>
        {
            state.email = action.payload;
        },
        changeGeolocation: (state, action: PayloadAction<GeolocationData>) =>
        {
            state.geolocation = action.payload;
        },
        changeGeolocationFilter: (state, action: PayloadAction<CaptureParams>) =>
            {
                state.geolocationFilter = action.payload;
            },
        changeGeolocationlistDevice: (
            state,
            action: PayloadAction<GeolocationData>
        ) =>
        {
            state.geolocationlistDevice = action.payload;
        },
        changeTextSMS: (state, action: PayloadAction<TextSMSData>) =>
        {
            state.textSMS = action.payload;
        },


        changeTextMMS: (state, action: PayloadAction<TextMMSData>) =>
        {
            state.textMMS = action.payload;
        },


        changeInstalledProgramsDetails: (state, action: PayloadAction<InstalledProgramsData>) =>
        {
            state.installedProgramsDetails = action.payload;
        },
        setPrintedDocumentDetails: (state, action: PayloadAction<PrintedDocumentData |
            undefined>) =>
        {
            state.printedDocumentDetails = action.payload;
        },
        clearFlagStatus: (state) =>
        {
            state.updateFlagStatus = RequestStatus.NotStarted;
        },
        resetInstalledProgramsByDeviceStatus: (state) =>
        {
            state.installedProgramsByDeviceStatus = RequestStatus.NotStarted;
        },

        resetscreenshotFilterFlag: (state) =>
        {
            state.filterflagValues = [];
        },
        changeProgram: (state, action: PayloadAction<ProgramData[]>) =>
        {
            state.appscompare = action.payload;
        },
        changescreenshotStatus: (state) =>
        {
            state.screenshotsStatus = RequestStatus.NotStarted;
        },
        changeSMSIdStatus: (state) =>
        {
            state.SMSIdstatus = RequestStatus.NotStarted;
            state.SMSIds = undefined;
        },
        changeAlertsIdStatus: (state) =>
        {
            state.AlertsIdstatus = RequestStatus.NotStarted;
            state.AlertsIds = undefined;
        },
        changeAlertsJPEGStatus: (state) =>
        {
            state.AlertsJPEGstatus = RequestStatus.NotStarted;
            state.AlertsJPEG = undefined;
        },
        changeViewDataIdStatus: (state) =>
        {
            state.ViewDataIdstatus = RequestStatus.NotStarted;
            state.ViewDataIds = undefined;
        },
        changeViewDataJPEGStatus: (state) =>
        {
            state.ViewDataJPEGstatus = RequestStatus.NotStarted;
            state.ViewDataJPEG = undefined;
        },
        changeFilterFlag: (state, action) =>
        {
            state.filterflagValues = action.payload;

        },
        changeAlertsData: (state) =>
        {
            state.alertsGridData = {
                contents: [],
                numberOfEntries: 0,
                currentPage: 1,
            };
            state.alerts = {
                contents: [],
                numberOfEntries: 0,
                currentPage: 1,
            };
        },
        changeaiFlagFilterData: (state, action) =>
        {
            state.aiFlagFilter = action.payload;
        },
        resetaiFlagFilterData: (state, action) =>
        {
            if (action.payload === 'viewData')
            {
                state.aiFlagFilter = Object.values(FlagWithAI).map((flag) =>
                {
                    return flag;
                }).filter(Boolean) as string[];

            }
            else
            {

                state.aiFlagFilter = Object.values(FlagWithAI).map((flag) =>
                {
                    if (flag === FlagWithAI.NO_FLAG)
                    {
                        return null;
                    }
                    return flag;
                }).filter(Boolean) as string[];

            }
        },
        changesAlertsFilterData: (state, action) =>
        {
            state.alertsFilterData = action.payload;
        },
        resetAlertsFilterData: (state) =>
        {
            state.alertsFilterData = [];
        },
        resettextMMSsData: (state) =>
        {
            state.textMMSs = {
                contents: [],
                numberOfEntries: 0,
                currentPage: 1,
            };
        },
        resetscreenCapturesData: (state) =>
        {
            state.screenCaptures = {
                contents: [],
                numberOfEntries: 0,
                currentPage: 1,
            };
        },
        resetPhotosData: (state) =>
        {
            state.photos = {
                contents: [],
                numberOfEntries: 0,
                currentPage: 1,
            };
        },
        changeactivedatasearchValue: (state, action) =>
        {
            state.activedatasearchValue = action.payload;
        },
        changeactivedataPlatformValue: (state, action) =>
        {
            state.activedataPlatformValue = action.payload;
        },
        changeinactivedatasearchValue: (state, action) =>
        {
            state.inactivedatasearchValue = action.payload;
        },
        resetactivedatasearchValue: (state) =>
        {
            state.activedatasearchValue = '';
            state.inactivedatasearchValue = '';
            state.activedataPlatformValue = undefined;
        },
        resetCardDataStatus: (state) =>
        {
            state.cardDataIds = '';
            state.cardDataStatus = RequestStatus.NotStarted;
        },
        changeAllPageData: (state, action) =>
        {
            const { page, data } = action.payload;
            state.allPageData[page] = data;
        },
    },
    extraReducers: (builder) =>
    {
        builder
            .addCase(getAppsCompareDetails.pending, (state) =>
            {
                state.appscompareStatus = RequestStatus.InProgress;
            })
            .addCase(getAppsCompareDetails.fulfilled, (state, action) =>
            {
                state.appscompare = action.payload;
                state.appscompareStatus = RequestStatus.Success;
            })
            .addCase(getAppsCompareDetails.rejected, (state) =>
            {
                state.appscompare = undefined;
                state.appscompareStatus = RequestStatus.Failure;
            })
            .addCase(getKeystrokes.pending, (state) =>
            {
                state.keystrokesStatus = RequestStatus.InProgress;
            })
            .addCase(getKeystrokes.fulfilled, (state, action) =>
            {
                state.keystrokes = action.payload;
                state.keystrokesStatus = RequestStatus.Success;
            })
            .addCase(getKeystrokes.rejected, (state) =>
            {
                state.keystrokes = undefined;
                state.keystrokesStatus = RequestStatus.Failure;
            })
            .addCase(getKeystrokeDetails.pending, (state) =>
            {
                state.keystrokeDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getKeystrokeDetails.fulfilled, (state, action) =>
            {
                state.keystrokeDetails = action.payload;
                state.keystrokeDetailsStatus = RequestStatus.Success;
            })
            .addCase(getKeystrokeDetails.rejected, (state) =>
            {
                state.keystrokeDetails = undefined;
                state.keystrokeDetailsStatus = RequestStatus.Failure;
            })
            .addCase(getSearchDetails.pending, (state) =>
            {
                state.searchDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getSearchDetails.fulfilled, (state, action) =>
            {
                state.searchDetails = action.payload;
                state.searchDetailsStatus = RequestStatus.Success;
            })
            .addCase(getSearchDetails.rejected, (state) =>
            {
                state.searchDetails = undefined;
                state.searchDetailsStatus = RequestStatus.Failure;
            })
            .addCase(getProcessDetails.pending, (state) =>
            {
                state.processDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getProcessDetails.fulfilled, (state, action) =>
            {
                state.processDetails = action.payload;
                state.processDetailsStatus = RequestStatus.Success;
            })
            .addCase(getProcessDetails.rejected, (state) =>
            {
                state.processDetails = undefined;
                state.processDetailsStatus = RequestStatus.Failure;
            })
            .addCase(getCallHistoryDetails.pending, (state) =>
            {
                state.callHistoryDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getCallHistoryDetails.fulfilled, (state, action) =>
            {
                state.callHistoryDetails = action.payload;
                state.callHistoryDetailsStatus = RequestStatus.Success;
            })
            .addCase(getCallHistoryDetails.rejected, (state) =>
            {
                state.callHistoryDetails = undefined;
                state.callHistoryDetailsStatus = RequestStatus.Failure;
            })
            .addCase(getScreenshots.pending, (state) =>
            {
                state.screenshotsStatus = RequestStatus.InProgress;
            })
            .addCase(getScreenshots.fulfilled, (state, action) =>
            {
                state.screenshots = action.payload;
                state.screenshotsStatus = RequestStatus.Success;
            })
            .addCase(getScreenshots.rejected, (state) =>
            {
                state.screenshots = undefined;
                state.screenshotsStatus = RequestStatus.Failure;
            })
            .addCase(getScreenshotsMeta.pending, (state) =>
            {
                state.screenshotsStatus = RequestStatus.InProgress;
            })
            .addCase(getScreenshotsMeta.fulfilled, (state, action) =>
            {
                state.screenshotsMeta = action.payload;
                state.screenshotsStatus = RequestStatus.Success;
            })
            .addCase(getScreenshotsMeta.rejected, (state) =>
            {
                state.screenshotsMeta = undefined;
                state.screenshotsStatus = RequestStatus.Failure;
            })
            .addCase(getScreenshotsdeviceid.pending, (state) =>
            {
                state.screenshotsStatus = RequestStatus.InProgress;
            })
            .addCase(getScreenshotsdeviceid.fulfilled, (state, action) =>
            {
                state.screenshotsdeviceid = action.payload;
                state.screenshotsStatus = RequestStatus.Success;
            })
            .addCase(getScreenshotsdeviceid.rejected, (state) =>
            {
                state.screenshotsdeviceid = undefined;
                state.screenshotsStatus = RequestStatus.Failure;
            })

            .addCase(getScreenshotsMetadevicebyid.pending, (state) =>
            {
                state.screenshotsdevicebyid = undefined;
                state.screenshotsStatus = RequestStatus.InProgress;

            })
            .addCase(getScreenshotsMetadevicebyid.fulfilled, (state, action) =>
            {
                state.screenshotsdevicebyid = action.payload;
                state.screenshotsStatus = RequestStatus.Success;
            })
            .addCase(getScreenshotsMetadevicebyid.rejected, (state) =>
            {
                state.screenshotsdevicebyid = undefined;
                state.screenshotsStatus = RequestStatus.Failure;

            })
            .addCase(getScreenCaptures.pending, (state) =>
            {
                state.screenCapturesStatus = RequestStatus.InProgress;
            })
            .addCase(getScreenCaptures.fulfilled, (state, action) =>
            {
                if (state.filters.isGridView === false || action.payload.currentPage === 1)
                {
                    // we refresh the entire contents of the list when the current page is 1,
                    // or if the layout is list
                    state.screenCaptures = action.payload;
                }
                else
                {
                    state.screenCaptures = {
                        ...action.payload,
                        contents: [
                            ...(state.screenCaptures?.contents ?? []),
                            ...action.payload.contents,
                        ],
                    };
                }
                state.screenCapturesStatus = RequestStatus.Success;
            })
            .addCase(getScreenCaptures.rejected, (state) =>
            {
                state.screenCaptures = undefined;
                state.screenCapturesStatus = RequestStatus.Failure;
            })
            .addCase(getScreenCapturesIDS.pending, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.InProgress;
                state.ViewDataIds = [];
            })
            .addCase(getScreenCapturesIDS.fulfilled, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.Success;
                state.ViewDataIds = action.payload;
            })
            .addCase(getScreenCapturesIDS.rejected, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.InProgress;
                state.ViewDataIds = undefined;
            })
            .addCase(getScreenCapturesJPEG.pending, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.InProgress;
                state.ViewDataJPEG = [];
            })
            .addCase(getScreenCapturesJPEG.fulfilled, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.Success;
                state.ViewDataJPEG = action.payload;
            })
            .addCase(getScreenCapturesJPEG.rejected, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.InProgress;
                state.ViewDataJPEG = undefined;
            })
            .addCase(getPhotos.pending, (state) =>
            {
                state.photosStatus = RequestStatus.InProgress;
            })
            .addCase(getPhotos.fulfilled, (state, action) =>
            {
                if (action.payload)
                {
                    state.photos = action.payload;
                }
                state.photosStatus = RequestStatus.Success;
            })
            .addCase(getPhotos.rejected, (state) =>
            {
                state.photos = undefined;
                state.photosStatus = RequestStatus.Failure;
            })
            .addCase(getPhotosIDS.pending, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.InProgress;
                state.ViewDataIds = [];
            })
            .addCase(getPhotosIDS.fulfilled, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.Success;
                state.ViewDataIds = action.payload;
            })
            .addCase(getPhotosIDS.rejected, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.InProgress;
                state.ViewDataIds = undefined;
            })
            .addCase(getPhotosJPEG.pending, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.InProgress;
                state.ViewDataJPEG = [];
            })
            .addCase(getPhotosJPEG.fulfilled, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.Success;
                state.ViewDataJPEG = action.payload;
            })
            .addCase(getPhotosJPEG.rejected, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.InProgress;
                state.ViewDataJPEG = undefined;
            })
            .addCase(getTextSMSs.pending, (state) =>
            {
                state.textSMSsStatus = RequestStatus.InProgress;
            })
            .addCase(getTextSMSs.fulfilled, (state, action) =>
            {
                state.textSMSs = action.payload;
                state.textSMSsStatus = RequestStatus.Success;
            })
            .addCase(getTextSMSs.rejected, (state) =>
            {
                state.textSMSs = undefined;
                state.textSMSsStatus = RequestStatus.Failure;
            })
            .addCase(getTextSMSsForDetails.pending, (state) =>
            {
                state.textSMSsForDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getTextSMSsForDetails.fulfilled, (state, action) =>
            {
                state.textSMSsForDetails = action.payload;
                state.textSMSsForDetailsStatus = RequestStatus.Success;
            })
            .addCase(getTextSMSsForDetails.rejected, (state) =>
            {
                state.textSMSsForDetails = undefined;
                state.textSMSsForDetailsStatus = RequestStatus.Failure;
            })



            .addCase(getTextMMSs.pending, (state) =>
            {
                state.textMMSsStatus = RequestStatus.InProgress;
            })
            .addCase(getTextMMSs.fulfilled, (state, action) =>
            {
                if (action.payload)
                {
                    if (state.filters.isGridView === false ||
                        action.payload.currentPage === 1
                    )
                    {
                        // we refresh the entire contents of the list when the current page is 1,
                        // or if the layout is list
                        state.textMMSs = action.payload;
                    }
                    else
                    {
                        state.textMMSs = action.payload;
                    }
                }
                else
                {
                    state.textMMSsStatus = RequestStatus.Failure;
                }
                state.textMMSsStatus = RequestStatus.Success;
            })
            .addCase(getTextMMSs.rejected, (state) =>
            {
                state.textMMSs = undefined;
                state.textMMSsStatus = RequestStatus.Failure;
            })
            .addCase(getTextMMSIDS.pending, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.InProgress;
                state.ViewDataIds = [];
            })
            .addCase(getTextMMSIDS.fulfilled, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.Success;
                state.ViewDataIds = action.payload;
            })
            .addCase(getTextMMSIDS.rejected, (state, action) =>
            {
                state.ViewDataIdstatus = RequestStatus.InProgress;
                state.ViewDataIds = undefined;
            })
            .addCase(getTextMMSJPEG.pending, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.InProgress;
                state.ViewDataJPEG = [];
            })
            .addCase(getTextMMSJPEG.fulfilled, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.Success;
                state.ViewDataJPEG = action.payload;
            })
            .addCase(getTextMMSJPEG.rejected, (state, action) =>
            {
                state.ViewDataJPEGstatus = RequestStatus.InProgress;
                state.ViewDataJPEG = undefined;
            })
            .addCase(getExternalDevices.pending, (state) =>
            {
                state.externalDevicesStatus = RequestStatus.InProgress;
            })
            .addCase(getExternalDevices.fulfilled, (state, action) =>
            {
                state.externalDevices = action.payload;
                state.externalDevicesStatus = RequestStatus.Success;
            })
            .addCase(getExternalDevices.rejected, (state) =>
            {
                state.externalDevices = undefined;
                state.externalDevicesStatus = RequestStatus.Failure;
            })
            .addCase(getExternalDeviceDetails.pending, (state) =>
            {
                state.externalDeviceDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getExternalDeviceDetails.fulfilled, (state, action) =>
            {
                state.externalDeviceDetails = action.payload;
                state.externalDeviceDetailsStatus = RequestStatus.Success;
            })
            .addCase(getExternalDeviceDetails.rejected, (state) =>
            {
                state.externalDeviceDetails = undefined;
                state.externalDeviceDetailsStatus = RequestStatus.Failure;
            })
            .addCase(getFilesTracking.pending, (state) =>
            {
                state.filesTrackingStatus = RequestStatus.InProgress;
            })
            .addCase(getFilesTracking.fulfilled, (state, action) =>
            {
                state.filesTracking = action.payload;
                state.filesTrackingStatus = RequestStatus.Success;
            })
            .addCase(getFilesTracking.rejected, (state) =>
            {
                state.filesTracking = undefined;
                state.filesTrackingStatus = RequestStatus.Failure;
            })
            .addCase(getFileTrackingDetails.pending, (state) =>
            {
                state.fileTrackingDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getFileTrackingDetails.fulfilled, (state, action) =>
            {

                state.fileTrackingDetails = action.payload;
                state.fileTrackingDetailsStatus = RequestStatus.Success;
            })
            .addCase(getFileTrackingDetails.rejected, (state) =>
            {
                state.fileTrackingDetails = undefined;
                state.fileTrackingDetailsStatus = RequestStatus.Failure;
            })
            .addCase(getInstalledPrograms.pending, (state) =>
            {
                state.installedProgramsStatus = RequestStatus.InProgress;
            })
            .addCase(getInstalledPrograms.fulfilled, (state, action) =>
            {
                state.installedPrograms = action.payload;
                state.installedProgramsStatus = RequestStatus.Success;
            })
            .addCase(getInstalledPrograms.rejected, (state) =>
            {
                state.installedPrograms = undefined;
                state.installedProgramsStatus = RequestStatus.Failure;
            })
            .addCase(getGeolocations.pending, (state) =>
            {
                state.geolocationsStatus = RequestStatus.InProgress;
            })
            .addCase(getGeolocations.fulfilled, (state, action) =>
            {
                state.geolocations = action.payload;
                state.geolocationsStatus = RequestStatus.Success;
            })
            .addCase(getGeolocations.rejected, (state) =>
            {
                state.geolocations = undefined;
                state.geolocationsStatus = RequestStatus.Failure;
            })

            .addCase(getGeolocationslistDevice.pending, (state) =>
            {
                state.geolocationslistDevice = {
                    contents: [],
                    currentPage: 1,
                    numberOfEntries: 0,
                };
                state.geolocationslistDeviceStatus = RequestStatus.InProgress;
            })
            .addCase(getGeolocationslistDevice.fulfilled, (state, action) =>
            {
                state.geolocationslistDevice = action.payload;
                state.geolocationslistDeviceStatus = RequestStatus.Success;
            })
            .addCase(getGeolocationslistDevice.rejected, (state) =>
            {
                state.geolocationslistDevice = undefined;
                state.geolocationslistDeviceStatus = RequestStatus.Failure;
            })

            .addCase(getGeolocationsByDate.pending, (state) =>
            {
                state.geolocationsByDateStatus = RequestStatus.InProgress;
            })
            .addCase(getGeolocationsByDate.fulfilled, (state, action) =>
            {
                state.geolocationsByDate = action.payload;
                state.geolocationsByDateStatus = RequestStatus.Success;
            })
            .addCase(getGeolocationsByDate.rejected, (state) =>
            {
                state.geolocationsByDate = undefined;
                state.geolocationsByDateStatus = RequestStatus.Failure;
            })
            .addCase(getEmails.pending, (state) =>
            {
                state.emailsStatus = RequestStatus.InProgress;
            })
            .addCase(getEmails.fulfilled, (state, action) =>
            {
                state.emails = action.payload;
                state.emailsStatus = RequestStatus.Success;
            })
            .addCase(getEmails.rejected, (state) =>
            {
                state.emails = undefined;
                state.emailsStatus = RequestStatus.Failure;
            })
            .addCase(getEmailAttachments.pending, (state) =>
            {
                state.emailAttachmentsStatus = RequestStatus.InProgress;
                state.emailAttachments = [];
            })
            .addCase(getEmailAttachments.fulfilled, (state, action) =>
            {
                state.emailAttachmentsStatus = RequestStatus.Success;
                state.emailAttachments = action.payload;
            })
            .addCase(getEmailAttachments.rejected, (state) =>
            {
                state.emailAttachmentsStatus = RequestStatus.Failure;
                state.emailAttachments = [];
            })
            .addCase(getLogonEvents.pending, (state) =>
            {
                state.logonEventsStatus = RequestStatus.InProgress;
            })
            .addCase(getLogonEvents.fulfilled, (state, action) =>
            {
                state.logonEvents = action.payload;
                state.logonEventsStatus = RequestStatus.Success;
            })
            .addCase(getLogonEvents.rejected, (state) =>
            {
                state.logonEvents = undefined;
                state.logonEventsStatus = RequestStatus.Failure;
            })
            .addCase(getCallHistory.pending, (state) =>
            {
                state.callHistoryStatus = RequestStatus.InProgress;
            })
            .addCase(getCallHistory.fulfilled, (state, action) =>
            {
                state.callHistory = action.payload;
                state.callHistoryStatus = RequestStatus.Success;
            })
            .addCase(getCallHistory.rejected, (state) =>
            {
                state.callHistory = undefined;
                state.callHistoryStatus = RequestStatus.Failure;
            })
            .addCase(getAllToExportDatas.pending, (state) =>
            {
                state.ExportDataAllRecordStatus = RequestStatus.InProgress;
            })
            .addCase(getAllToExportDatas.fulfilled, (state, action) =>
            {
                state.ExportDataAllRecord = action.payload;
                state.ExportDataAllRecordStatus = RequestStatus.Success;
            })
            .addCase(getAllToExportDatas.rejected, (state) =>
            {
                state.ExportDataAllRecord = undefined;
                state.ExportDataAllRecordStatus = RequestStatus.Failure;
            })

            .addCase(getalerts.pending, (state) =>
            {
                state.alertsStatus = RequestStatus.InProgress;
            })
            .addCase(getalerts.fulfilled, (state, action) =>
            {
                if (state.alertsFilter.isGridView === true &&
                    (state.alertsFilter.alertsdatatype === 'MMS' ||
                        state.alertsFilter.alertsdatatype === 'Photos' ||
                        state.alertsFilter.alertsdatatype === 'Screen Captures' ||
                        state.alertsFilter.alertsdatatype === 'Screenshots'
                    ))
                {
                    state.alertsGridData = action.payload;
                    state.alertsStatus = RequestStatus.Success;
                }
                else
                {
                    state.alerts = action.payload;
                    state.alertsStatus = RequestStatus.Success;
                }
            })
            .addCase(getalerts.rejected, (state) =>
            {
                state.alerts = undefined;
                state.alertsStatus = RequestStatus.Failure;
            })
            .addCase(getalertsIdsDetails.pending, (state, action) =>
            {
                state.AlertsIdstatus = RequestStatus.InProgress;
                state.AlertsIds = [];
            })
            .addCase(getalertsIdsDetails.fulfilled, (state, action) =>
            {
                state.AlertsIdstatus = RequestStatus.Success;
                state.AlertsIds = action.payload;
            })
            .addCase(getalertsIdsDetails.rejected, (state, action) =>
            {
                state.AlertsIdstatus = RequestStatus.InProgress;
                state.AlertsIds = undefined;
            })
            .addCase(getalertsJPEGDetails.pending, (state, action) =>
            {
                state.AlertsJPEGstatus = RequestStatus.InProgress;
                state.AlertsJPEG = [];
            })
            .addCase(getalertsJPEGDetails.fulfilled, (state, action) =>
            {
                state.AlertsJPEGstatus = RequestStatus.Success;
                state.AlertsJPEG = action.payload;
            })
            .addCase(getalertsJPEGDetails.rejected, (state, action) =>
            {
                state.AlertsJPEGstatus = RequestStatus.InProgress;
                state.AlertsJPEG = undefined;
            })
            .addCase(getAlertsDetails.pending, (state) =>
            {
                state.alertsDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getAlertsDetails.fulfilled, (state, action) =>
            {
                state.alertsDetails = action.payload;
                state.alertsDetailsStatus = RequestStatus.Success;
            })
            .addCase(getAlertsDetails.rejected, (state) =>
            {
                state.alertsDetails = undefined;
                state.alertsDetailsStatus = RequestStatus.Failure;
            })

            .addCase(updateSeenStatus.pending, (state) =>
            {
                state.updateSeenDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(updateSeenStatus.fulfilled, (state, action) =>
            {
                state.updateSeenDetails = action.payload;
                state.updateSeenDetailsStatus = RequestStatus.Success;
            })
            .addCase(updateSeenStatus.rejected, (state) =>
            {
                state.alertsDetails = undefined;
                state.updateSeenDetailsStatus = RequestStatus.Failure;
            })

            .addCase(getActiveDevices.pending, (state) =>
            {
                state.activedataStatus = RequestStatus.InProgress;
            })
            .addCase(getActiveDevices.fulfilled, (state, action) =>
            {
                state.activedata = action.payload;
                state.activedataStatus = RequestStatus.Success;
            })
            .addCase(getActiveDevices.rejected, (state) =>
            {
                state.activedata = undefined;
                state.activedataStatus = RequestStatus.Failure;
            })
            .addCase(getAllActiveDevices.pending, (state) =>
            {
                state.activedataStatus = RequestStatus.InProgress;
            })
            .addCase(getAllActiveDevices.fulfilled, (state, action) =>
            {
                state.allActivedata = action.payload;
                state.activedataStatus = RequestStatus.Success;
            })
            .addCase(getAllActiveDevices.rejected, (state) =>
            {
                state.activedata = undefined;
                state.activedataStatus = RequestStatus.Failure;
            })
            .addCase(getActiveDevicesId.pending, (state) =>
            {
                state.cardDataStatus = RequestStatus.InProgress;
            })
            .addCase(getActiveDevicesId.fulfilled, (state, action) =>
            {
                state.cardDataIds = action.payload;
                state.cardDataStatus = RequestStatus.Success;
            })
            .addCase(getActiveDevicesId.rejected, (state) =>
            {
                state.cardDataIds = undefined;
                state.cardDataStatus = RequestStatus.Failure;
            })
            .addCase(getInActiveDevices.pending, (state) =>
            {
                state.inactivedataStatus = RequestStatus.InProgress;
            })
            .addCase(getInActiveDevices.fulfilled, (state, action) =>
            {
                state.inactivedata = action.payload;
                state.inactivedataStatus = RequestStatus.Success;
            })
            .addCase(getInActiveDevices.rejected, (state) =>
            {
                state.inactivedata = undefined;
                state.inactivedataStatus = RequestStatus.Failure;
            })
            .addCase(getallInActiveDevices.pending, (state) =>
            {
                state.allinactivedataStatus = RequestStatus.InProgress;
            })
            .addCase(getallInActiveDevices.fulfilled, (state, action) =>
            {
                state.allinactivedata = action.payload;
                state.allinactivedataStatus = RequestStatus.Success;
            })
            .addCase(getallInActiveDevices.rejected, (state) =>
            {
                state.allinactivedata = undefined;
                state.allinactivedataStatus = RequestStatus.Failure;
            })
            .addCase(getInActiveDevicesId.pending, (state) =>
            {
                state.cardDataStatus = RequestStatus.InProgress;
            })
            .addCase(getInActiveDevicesId.fulfilled, (state, action) =>
            {
                state.cardDataIds = action.payload;
                state.cardDataStatus = RequestStatus.Success;
            })
            .addCase(getInActiveDevicesId.rejected, (state) =>
            {
                state.cardDataIds = undefined;
                state.cardDataStatus = RequestStatus.Failure;
            })
            .addCase(getRedFlagged.pending, (state) =>
            {

                state.redflagcountStatus = RequestStatus.InProgress;
            })
            .addCase(getRedFlagged.fulfilled, (state, action) =>
            {
                state.redflagcount = action.payload;
                state.redflagcountStatus = RequestStatus.Success;
            })
            .addCase(getRedFlagged.rejected, (state) =>
            {
                state.redflagcount = undefined;
                state.redflagcountStatus = RequestStatus.Failure;
            })
            .addCase(getRecord.pending, (state) =>
            {

                state.recordcountStatus = RequestStatus.InProgress;
            })
            .addCase(getRecord.fulfilled, (state, action) =>
            {
                state.recordcount = action.payload;
                state.recordcountStatus = RequestStatus.Success;
            })
            .addCase(getRecord.rejected, (state) =>
            {
                state.redflagcount = undefined;
                state.recordcountStatus = RequestStatus.Failure;
            })
            .addCase(getPrintedDocuments.pending, (state) =>
            {
                state.printedDocumentsStatus = RequestStatus.InProgress;
            })
            .addCase(getPrintedDocuments.fulfilled, (state, action) =>
            {
                state.printedDocuments = action.payload;
                state.printedDocumentsStatus = RequestStatus.Success;
            })
            .addCase(getPrintedDocuments.rejected, (state) =>
            {
                state.printedDocuments = undefined;
                state.printedDocumentsStatus = RequestStatus.Failure;
            })
            .addCase(getProcesses.pending, (state) =>
            {
                state.processesStatus = RequestStatus.InProgress;
            })
            .addCase(getProcesses.fulfilled, (state, action) =>
            {
                state.processes = action.payload;
                state.processesStatus = RequestStatus.Success;
            })
            .addCase(getProcesses.rejected, (state) =>
            {
                state.processes = undefined;
                state.processesStatus = RequestStatus.Failure;
            })
            .addCase(getAgencyFilterOptions.pending, (state) =>
            {
                state.agencyFilterOptionsStatus = RequestStatus.InProgress;
            })
            .addCase(getAgencyFilterOptions.fulfilled, (state, action) =>
            {
                state.agencyFilterOptionsStatus = RequestStatus.Success;
                state.agencyFilterOptions = action.payload;
            })
            .addCase(getAgencyFilterOptions.rejected, (state) =>
            {
                state.agencyFilterOptions = undefined;
                state.agencyFilterOptionsStatus = RequestStatus.Failure;
            })
            .addCase(getOfficerFilterOptions.pending, (state) =>
            {
                state.officerFilterOptionsStatus = RequestStatus.InProgress;
            })
            .addCase(getOfficerFilterOptions.fulfilled, (state, action) =>
            {
                state.officerFilterOptionsStatus = RequestStatus.Success;
                state.officerFilterOptions = action.payload;
            })
            .addCase(getOfficerFilterOptions.rejected, (state) =>
            {
                state.officerFilterOptions = undefined;
                state.officerFilterOptionsStatus = RequestStatus.Failure;
            })
            .addCase(getClientFilterOptions.pending, (state) =>
            {
                state.clientFilterOptionsStatus = RequestStatus.InProgress;
            })
            .addCase(getClientFilterOptions.fulfilled, (state, action) =>
            {
                state.clientFilterOptionsStatus = RequestStatus.Success;
                state.clientFilterOptions = action.payload;
            })
            .addCase(getClientFilterOptions.rejected, (state) =>
            {
                state.clientFilterOptions = undefined;
                state.clientFilterOptionsStatus = RequestStatus.Failure;
            })
            .addCase(getDeviceFilterOptions.pending, (state) =>
            {
                state.deviceFilterOptionsStatus = RequestStatus.InProgress;
            })
            .addCase(getDeviceFilterOptions.fulfilled, (state, action) =>
            {
                state.deviceFilterOptionsStatus = RequestStatus.Success;
                state.deviceFilterOptions = action.payload;
            })
            .addCase(getDeviceFilterOptions.rejected, (state) =>
            {
                state.deviceFilterOptions = undefined;
                state.deviceFilterOptionsStatus = RequestStatus.Failure;
            })
            .addCase(getVersionsFilterOptions.pending, (state) =>
            {
                state.deviceVersionFilterOptionsStatus = RequestStatus.InProgress;
            })
            .addCase(getVersionsFilterOptions.fulfilled, (state, action) =>
            {
                state.deviceVersionFilterOptionsStatus = RequestStatus.Success;
                state.deviceVersionFilterOptions = action.payload;
            })
            .addCase(getVersionsFilterOptions.rejected, (state) =>
            {
                state.deviceVersionFilterOptions = undefined;
                state.deviceVersionFilterOptionsStatus = RequestStatus.Failure;
            })
            .addCase(getDeviceFilterOptionsById.pending, (state) =>
            {
                state.deviceFilterOptionsByIdStatus = RequestStatus.InProgress;
            })
            .addCase(getDeviceFilterOptionsById.fulfilled, (state, action) =>
            {
                state.deviceFilterOptionsByIdStatus = RequestStatus.Success;
                state.deviceFilterOptionsById = action.payload;
            })
            .addCase(getDeviceFilterOptionsById.rejected, (state) =>
            {
                state.deviceFilterOptionsByIdStatus = RequestStatus.Failure;
                state.deviceFilterOptionsById = undefined;
            })
            .addCase(getWebsites.pending, (state) =>
            {
                state.websitesStatus = RequestStatus.InProgress;
            })
            .addCase(getWebsites.fulfilled, (state, action) =>
            {
                state.websites = action.payload;
                state.websitesStatus = RequestStatus.Success;
            })
            .addCase(getWebsites.rejected, (state) =>
            {
                state.websites = undefined;
                state.websitesStatus = RequestStatus.Failure;
            })
            .addCase(getWebsiteDetails.pending, (state) =>
            {
                state.websiteDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getWebsiteDetails.fulfilled, (state, action) =>
            {
                state.websiteDetails = action.payload;
                state.websiteDetailsStatus = RequestStatus.Success;
            })
            .addCase(getWebsiteDetails.rejected, (state) =>
            {
                state.websiteDetails = undefined;
                state.websiteDetailsStatus = RequestStatus.Failure;
            })
            .addCase(getSearches.pending, (state) =>
            {
                state.searchesStatus = RequestStatus.InProgress;
            })
            .addCase(getSearches.fulfilled, (state, action) =>
            {
                state.searches = action.payload;
                state.searchesStatus = RequestStatus.Success;
            })
            .addCase(getSearches.rejected, (state) =>
            {
                state.searches = undefined;
                state.searchesStatus = RequestStatus.Failure;
            })
            .addCase(updateFlag.pending, (state) =>
            {
                state.updateFlagStatus = RequestStatus.InProgress;
            })
            .addCase(updateFlag.fulfilled, (state, action) =>
            {
                state.updatedFlag = action.payload;
                state.updateFlagStatus = RequestStatus.Success;
            })
            .addCase(updateFlag.rejected, (state) =>
            {
                state.updatedFlag = undefined;
                state.updateFlagStatus = RequestStatus.Failure;
            })
            .addCase(updateFlagToAll.pending, (state) =>
            {
                state.updateFlagAllStatus = RequestStatus.InProgress;
            })
            .addCase(updateFlagToAll.fulfilled, (state, action) =>
            {
                state.updatedFlagToAll = action.payload;
                state.updateFlagAllStatus = RequestStatus.Success;
            })
            .addCase(updateFlagToAll.rejected, (state) =>
            {
                state.updatedFlagToAll = undefined;
                state.updateFlagAllStatus = RequestStatus.Failure;
            })
            .addCase(getInstalledProgramsByDevice.pending, (state, action) =>
            {
                state.installedProgramsByDeviceStatus = RequestStatus.InProgress;
                state.installedProgramsByDevice = [];
            })
            .addCase(getInstalledProgramsByDevice.fulfilled, (state, action) =>
            {
                state.installedProgramsByDeviceStatus = RequestStatus.Success;
                state.installedProgramsByDevice = action.payload;
            })
            .addCase(getInstalledProgramsByDevice.rejected, (state, action) =>
            {
                state.installedProgramsByDeviceStatus = RequestStatus.Failure;
                state.installedProgramsByDevice = undefined;
            })
            .addCase(getSMSIdDetails.pending, (state, action) =>
            {
                state.SMSIdstatus = RequestStatus.InProgress;
                state.SMSIds = [];
            })
            .addCase(getSMSIdDetails.fulfilled, (state, action) =>
            {
                state.SMSIdstatus = RequestStatus.Success;
                state.SMSIds = action.payload;
            })
            .addCase(getSMSIdDetails.rejected, (state, action) =>
            {
                state.SMSIdstatus = RequestStatus.InProgress;
                state.SMSIds = undefined;
            });
    },
});

function selectState(state: RootState): CapturesState
{
    return state.captures;
}

export const selectFilters = createSelector(
    selectState,
    state => state.filters
);

export const selectscreenshotGridView = createSelector(
    selectState,
    state => state.screenshotGridView
);
export const selectcolumnValue = createSelector(
    selectState,
    (state) => state.columnValue
);
export const selectTableDatatype = createSelector(
    selectState,
    (state) => state.tableDatatype
);
export const selectTableTotalCount = createSelector(
    selectState,
    (state) => state.tableTotalCount
);
export const selectAlertsFilter = createSelector(
    selectState,
    state => state.alertsFilter
);

export const selectAlerts = createSelector(
    selectState,
    state => state.alerts
);

export const selectGridAlerts = createSelector(
    selectState,
    state => state.alertsGridData
);

export const selectAlertsStatus = createSelector(
    selectState,
    state => state.alertsStatus
);

export const selectAlertsDetails = createSelector(
    selectState,
    state => state.alertsDetails
);

export const selectAlertsDetailsStatus = createSelector(
    selectState,
    state => state.alertsDetailsStatus
);


export const selectUpdateSeenDetails = createSelector(
    selectState,
    state => state.updateSeenDetails
);

export const selectUserSeenDetailsStatus = createSelector(
    selectState,
    state => state.updateSeenDetailsStatus
);
export const selectActiveData = createSelector(
    selectState,
    state => state.activedata
);
export const selectAllActiveData = createSelector(
    selectState,
    state => state.allActivedata
);

export const selectActiveDataStatus = createSelector(
    selectState,
    state => state.activedataStatus
);

export const selectAllActiveDataStatus = createSelector(
    selectState,
    state => state.activedataStatus
);

export const selectInActiveData = createSelector(
    selectState,
    state => state.inactivedata
);

export const selectallInActiveData = createSelector(
    selectState,
    state => state.allinactivedata
);

export const selectInActiveDataStatus = createSelector(
    selectState,
    state => state.inactivedataStatus
);

export const selectcardDataIds = createSelector(
    selectState,
    state => state.cardDataIds
);

export const selectcardDataStatus = createSelector(
    selectState,
    state => state.cardDataStatus
);

export const selectRedFlagCount = createSelector(
    selectState,
    state => state.redflagcount
);

export const selectRedFlagStatus = createSelector(
    selectState,
    state => state.redflagcountStatus
);

export const selectRecordCount = createSelector(
    selectState,
    state => state.recordcount
);

export const selectRecordStatus = createSelector(
    selectState,
    state => state.recordcountStatus
);


export const selectSessionFilters = createSelector(
    selectState,
    state => state.sessionFilters
);
export const selectReportDateFilter = createSelector(
    selectState,
    state => state.reportsFilter
);

export const selectKeystrokes = createSelector(
    selectState,
    state => state.keystrokes
);

export const selectKeystrokesStatus = createSelector(
    selectState,
    state => state.keystrokesStatus
);

export const selectKeystrokeDetails = createSelector(
    selectState,
    state => state.keystrokeDetails
);

export const selectKeystrokeDetailsStatus = createSelector(
    selectState,
    state => state.keystrokeDetailsStatus
);

export const selectScreenshot = createSelector(
    selectState,
    state => state.screenshot
);
export const selectScreenshotDeviceId = createSelector(
    selectState,
    state => state.screenshotdeviceid
);

export const selectscreenshotModalData=createSelector(
    selectState,
    state => state.screenshotModalData
);

export const selectScreenshots = createSelector(
    selectState,
    state => state.screenshots
);
export const selectScreenshotsMeta = createSelector(
    selectState,
    state => state.screenshotsMeta
);
export const selectScreenshotsDeviceId = createSelector(
    selectState,
    state => state.screenshotsdeviceid
);
export const selectScreenshotsDeviceById = createSelector(
    selectState,
    state => state.screenshotsdevicebyid
);

export const selectScreenshotsStatus = createSelector(
    selectState,
    state => state.screenshotsStatus
);

export const selectScreenCaptures = createSelector(
    selectState,
    state => state.screenCaptures
);

export const selectScreenCapturesStatus = createSelector(
    selectState,
    state => state.screenCapturesStatus
);

export const selectScreenCapture = createSelector(
    selectState,
    state => state.screenCapture
);

export const selectPhotos = createSelector(
    selectState,
    state => state.photos
);

export const selectPhotosStatus = createSelector(
    selectState,
    state => state.photosStatus
);

export const selectPhoto = createSelector(
    selectState,
    state => state.photo
);

export const selectTextSMS = createSelector(
    selectState,
    state => state.textSMS
);

export const selectTextSMSs = createSelector(
    selectState,
    state => state.textSMSs
);

export const selectTextSMSsForDetails = createSelector(
    selectState,
    state => state.textSMSsForDetails
);

export const selectTextSMSsStatus = createSelector(
    selectState,
    state => state.textSMSsStatus
);


export const selectTextMMS = createSelector(
    selectState,
    state => state.textMMS
);

export const selectTextMMSs = createSelector(
    selectState,
    state => state.textMMSs
);

export const selectTextMMSsForDetails = createSelector(
    selectState,
    state => state.textMMSsForDetails
);

export const selectTextMMSsStatus = createSelector(
    selectState,
    state => state.textMMSsStatus
);



export const selectExternalDevices = createSelector(
    selectState,
    state => state.externalDevices
);

export const selectExternalDevicesStatus = createSelector(
    selectState,
    state => state.externalDevicesStatus
);

export const selectExternalDeviceDetails = createSelector(
    selectState,
    state => state.externalDeviceDetails
);

export const selectExternalDeviceDetailsStatus = createSelector(
    selectState,
    state => state.externalDeviceDetailsStatus
);

export const selectFilesTracking = createSelector(
    selectState,
    state => state.filesTracking
);

export const selectFilesTrackingStatus = createSelector(
    selectState,
    state => state.filesTrackingStatus
);

export const selectFileTrackingDetails = createSelector(
    selectState,
    state => state.fileTrackingDetails
);

export const selectFileTrackingDetailsStatus = createSelector(
    selectState,
    state => state.fileTrackingDetailsStatus
);

export const selectInstalledPrograms = createSelector(
    selectState,
    state => state.installedPrograms
);
export const selectappscompareStatus = createSelector(
    selectState,
    state => state.appscompareStatus
);
export const selectAppcompares = createSelector(
    selectState,
    state => state.appscompare
);
export const selectInstalledProgramsStatus = createSelector(
    selectState,
    state => state.installedProgramsStatus
);

export const selectInstalledProgramsDetails = createSelector(
    selectState,
    state => state.installedProgramsDetails
);

export const selectGeolocation = createSelector(
    selectState,
    state => state.geolocation
);

export const selectGeolocationFilter = createSelector(
    selectState,
    state => state.geolocationFilter
);


export const selectGeolocations = createSelector(
    selectState,
    state => state.geolocations
);

export const selectGeolocationsStatus = createSelector(
    selectState,
    (state) => state.geolocationsStatus
);
export const selectGeolocationslistDevice = createSelector(
    selectState,
    (state) => state.geolocationslistDevice
);
export const selectGeolocationslistDeviceStatus = createSelector(
    selectState,
    (state) => state.geolocationslistDeviceStatus
);
export const selectGeolocationsByDate = createSelector(
    selectState,
    (state) => state.geolocationsByDate
);

export const selectEmail = createSelector(selectState, (state) => state.email);

export const selectEmailAttachments = createSelector(selectState, (state) => ({
    attachments: state.emailAttachments,
    status: state.emailAttachmentsStatus,
}));

export const selectEmails = createSelector(
    selectState,
    state => state.emails
);

export const selectEmailsStatus = createSelector(
    selectState,
    state => state.emailsStatus
);

export const selectLogonEvents = createSelector(
    selectState,
    state => state.logonEvents
);

export const selectLogonEvent = createSelector(
    selectState,
    state => state.logonEvent
);

export const selectLogonEventsStatus = createSelector(
    selectState,
    state => state.logonEventsStatus
);

export const selectCallHistory = createSelector(
    selectState,
    state => state.callHistory
);
export const selectExportAllRecords = createSelector(
    selectState,
    (state) => state.ExportDataAllRecord
);

export const selectCallHistoryStatus = createSelector(
    selectState,
    state => state.callHistoryStatus
);

export const selectCallHistoryallRecordStatus = createSelector(
    selectState,
    (state) => state.ExportDataAllRecordStatus
);
export const selectCallHistoryIdStatus = createSelector(
    selectState,
    (state) => state.callHistoryIdStatus
);

export const selectCallHistoryDetails = createSelector(
    selectState,
    state => state.callHistoryDetails
);

export const selectCallHistoryDetailsStatus = createSelector(
    selectState,
    state => state.callHistoryDetailsStatus
);

export const selectPrintedDocumentDetails = createSelector(
    selectState,
    state => state.printedDocumentDetails
);

export const selectPrintedDocuments = createSelector(
    selectState,
    state => state.printedDocuments
);

export const selectPrintedDocumentsStatus = createSelector(
    selectState,
    state => state.printedDocumentsStatus
);

export const selectProcesses = createSelector(
    selectState,
    state => state.processes
);

export const selectProcessesStatus = createSelector(
    selectState,
    state => state.processesStatus
);

export const selectProcessDetails = createSelector(
    selectState,
    state => state.processDetails
);

export const selectProcessDetailsStatus = createSelector(
    selectState,
    state => state.processDetailsStatus
);

export const selectSearches = createSelector(
    selectState,
    state => state.searches
);

export const selectSearchesStatus = createSelector(
    selectState,
    state => state.searchesStatus
);

export const selectSearchDetails = createSelector(
    selectState,
    state => state.searchDetails
);

export const selectSearchDetailsStatus = createSelector(
    selectState,
    state => state.searchDetailsStatus
);

export const selectWebsites = createSelector(
    selectState,
    state => state.websites
);

export const selectWebsitesStatus = createSelector(
    selectState,
    state => state.websitesStatus
);

export const selectWebsiteDetails = createSelector(
    selectState,
    state => state.websiteDetails
);

export const selectWebsiteDetailsStatus = createSelector(
    selectState,
    state => state.websiteDetailsStatus
);

export const selectOfficerFilterOptions = createSelector(
    selectState,
    state => state.officerFilterOptions
);
export const selectOfficerFilterOptionsStatus = createSelector(
    selectState,
    state => state.officerFilterOptionsStatus
);

export const selectAgencyFilterOptions = createSelector(
    selectState,
    state => state.agencyFilterOptions
);
export const selectAgencyFilterOptionsStatus = createSelector(
    selectState,
    state => state.agencyFilterOptionsStatus
);

export const selectClientFilterOptions = createSelector(
    selectState,
    state => state.clientFilterOptions
);
export const selectClientFilterOptionsStatus = createSelector(
    selectState,
    state => state.clientFilterOptionsStatus
);

export const selectDeviceFilterOptions = createSelector(
    selectState,
    state => state.deviceFilterOptions
);
export const selectDeviceFilterOptionsStatus = createSelector(
    selectState,
    state => state.deviceFilterOptionsStatus
);

export const selectDeviceVersionFilterOptions = createSelector(
    selectState,
    state => state.deviceVersionFilterOptions
);
export const selectDeviceVersionFilterOptionsStatus = createSelector(
    selectState,
    state => state.deviceVersionFilterOptionsStatus
);

export const selectDeviceFilterOptionsById = createSelector(
    selectState,
    state => state.deviceFilterOptionsById
);

export const selectDeviceFilterOptionsByIdStatus = createSelector(
    selectState,
    state => state.deviceFilterOptionsByIdStatus
);

export const selectUpdatedFlag = createSelector(
    selectState,
    state => state.updatedFlag
);
export const selectUpdatedFlagAll = createSelector(
    selectState,
    state => state.updatedFlagToAll
);

export const selectUpdateFlagStatus = createSelector(
    selectState,
    state => state.updateFlagStatus
);

export const selectInstalledProgramsByDevice = createSelector(
    selectState,
    state => state.installedProgramsByDevice
);

export const selectInstalledProgramsByDeviceStatus = createSelector(
    selectState,
    state => state.installedProgramsByDeviceStatus
);

export const selectSMSIdStatus = createSelector(
    selectState,
    state => state.SMSIdstatus
);

export const selectAlertsIdStatus = createSelector(
    selectState,
    state => state.AlertsIdstatus
);

export const selectAlertsIds = createSelector(
    selectState,
    state => state.AlertsIds
);

export const selectAlertsJPEGStatus = createSelector(
    selectState,
    state => state.AlertsJPEGstatus
);

export const selectAlertsJPEG = createSelector(
    selectState,
    state => state.AlertsJPEG
);

export const selectViewDataIdStatus = createSelector(
    selectState,
    state => state.ViewDataIdstatus
);

export const selectViewDataIds = createSelector(
    selectState,
    state => state.ViewDataIds
);

export const selectViewDataJPEGStatus = createSelector(
    selectState,
    state => state.ViewDataJPEGstatus
);

export const selectViewDataJPEG = createSelector(
    selectState,
    state => state.ViewDataJPEG
);

export const screenshotFilterFlags = createSelector(
    selectState,
    state => state.filterflagValues
);

export const alertsFilterdata = createSelector(
    selectState,
    state => state.alertsFilterData
);

export const selectSMSIds = createSelector(
    selectState,
    state => state.SMSIds
);

export const selectaiFlagFilter = createSelector(
    selectState,
    state => state.aiFlagFilter
);

export const selectactivedatasearchValue = createSelector(
    selectState,
    state => state.activedatasearchValue
);
export const selectactivedataPlatformValue = createSelector(
    selectState,
    state => state.activedataPlatformValue
);

export const selectinactivedatasearchValue = createSelector(
    selectState,
    state => state.inactivedatasearchValue
);

export const selectallPageData = createSelector(
    selectState,
    state => state.allPageData
);

export const {
    changeScreenshotDetailsFilter,
    clearAgencyFilterOptions,
    clearClientFilterOptions,
    clearDeviceFilterOptions,
    clearDeviceFilterOptionsById,
    changeAlertsFilter,
    changeDateCheckedAlertsFilters,
    changeAgencyAlertsFilter,
    changeOfficerAlertsFilter,
    changeClientAlertsFilter,
    changeAlertsFlag,
    changeRedFlagCount,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
    changescreenshotGridViewFilters,
    changeDateCheckedFilters,
    changeSessionFilters,
    changeAgencyFilter,
    changeReportFilters,
    changeOfficerFilter,
    changeClientFilter,
    changeLogonEvent,
    changeScreenshot,
    changeScreenshotDetailsData,
    changeScreenshotDeviceId,
    changeScreenCapture,
    changePhoto,
    changeEmail,
    changeGeolocation,
    changeGeolocationFilter,
    changeTextSMS,
    changeTextMMS,
    changeInstalledProgramsDetails,
    setPrintedDocumentDetails,
    clearFlagStatus,
    resetInstalledProgramsByDeviceStatus,
    resetscreenshotFilterFlag,
    changeProgram,
    changescreenshotStatus,
    changeSMSIdStatus,
    changeAlertsIdStatus,
    changeAlertsJPEGStatus,
    changeViewDataIdStatus,
    changeViewDataJPEGStatus,
    changeAlertsData,
    changesAlertsFilterData,
    resetAlertsFilterData,
    changeFilterFlag,
    resettextMMSsData,
    resetscreenCapturesData,
    resetPhotosData,
    changeaiFlagFilterData,
    resetaiFlagFilterData,
    changeactivedatasearchValue,
    changeinactivedatasearchValue,
    changeactivedataPlatformValue,
    resetactivedatasearchValue,
    resetCardDataStatus,
    changeAllPageData,
} = capturesSlice.actions;

export default capturesSlice.reducer;
