import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { MenuOption, MenuOptionProps } from 'src/components/menu-option/MenuOption';
import { useNavigate } from 'react-router-dom';
import {
    AGENCY_MANAGEMENT,
    CLIENT_MANAGEMENT,
    DATA_TYPE,
    REPORTS_MANAGMENT,
    OFFICER_MANAGEMENT,
    ADMIN_SETTINGS,
    LANDING_PAGE,
} from 'src/utils/routes';
import { Role } from 'src/models/roles.enum';
import { userIsInRole } from 'src/utils/roleUtils';
import { ALERTSPAGE, PHOTOS, SCREENCAPTURES, SCREENSHOTS, TEXTMMS } from 'src/models/alertsType.enum';
import {
    changeAlertsFilter,
    changeaiFlagFilterData,
    selectAlertsFilter,
} from 'src/state/captures/capturesSlice';
import { clearSearchValue } from 'src/state/administration/administrationSlice';
import { Flag, FlagWithAI } from 'src/models/flag.enum';
import { useDispatch, useSelector } from 'react-redux';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { clearDeviceInfoSearchValue } from 'src/state/administrationMeta/administrationMetaSlice';

function HeaderMenu(): JSX.Element
{
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const alertsFilter = useSelector(selectAlertsFilter);
    const url = window.location.href;

    const adminSetting=['/administration/settings',
        '/administration/profiles',
        '/administration/alert-words/suggested',
        '/administration/users'];

    const adminHighlight=adminSetting.some((val)=> url.includes(val));


    const options: MenuOptionProps[] = [
        {
            label: t('menu.view-data'),
            onClick: () =>
            {
                dispatch( changeaiFlagFilterData (
                    Object.values(FlagWithAI).map((flag) =>
                    {
                        return flag;
                    }).filter(Boolean) as string[]
                ) );
                navigate(DATA_TYPE);
            },
            path: url.includes('/data-type/'),
        },
        {
            label: t('menu.alerts'),
            onClick: () =>
            {
                const updatedFilters: CaptureFilters = {
                    ...alertsFilter,
                };
        
                if(alertsFilter.alertsdatatype as string === TEXTMMS ||
                    alertsFilter.alertsdatatype as string === PHOTOS ||
                    alertsFilter.alertsdatatype as string === SCREENCAPTURES||
                    alertsFilter.alertsdatatype as string === SCREENSHOTS
            )
            {
                updatedFilters.flags =
                    Object.values(FlagWithAI)
                        .map((flag) => flag)
                        .filter((flag) => flag !== Flag.NO_FLAG);
            }
            else
            {
                updatedFilters.flags =
                    Object.values(Flag)
                        .map((flag) => flag)
                        .filter((flag) => flag !== Flag.NO_FLAG);
            }
            dispatch(changeAlertsFilter(updatedFilters));
                navigate(LANDING_PAGE);
            },
            path: url.includes(ALERTSPAGE),
        },
        {
            label: t('menu.my-reports'),
            onClick: () => navigate(REPORTS_MANAGMENT),
            path: url.includes('/administration/reports'),
        },
        {
            label: t('menu.settings'),
            options: [
                {
                    label: t('menu.admin-settings'),
                    onClick: () => {
                        navigate(ADMIN_SETTINGS);
                        dispatch(clearSearchValue());
                        dispatch(clearDeviceInfoSearchValue());
                    },
                    visible: userIsInRole([Role.Administrator]),
                    path: adminHighlight,
                },
                {
                    label: t('menu.agency-management'),
                    onClick: () => {
                        navigate(AGENCY_MANAGEMENT);
                        dispatch(clearSearchValue());
                        dispatch(clearDeviceInfoSearchValue());
                    },
                    visible: userIsInRole([
                        Role.Employee,
                        Role.Administrator,
                        Role.AgencyAdministrator,
                    ]),
                    path: url.includes('/administration/agencies'),
                },
                {
                    label: t('menu.officer-and-supervisors-management'),
                    onClick: () => {
                        navigate(OFFICER_MANAGEMENT);
                        dispatch(clearSearchValue());
                        dispatch(clearDeviceInfoSearchValue());
                    },
                    visible: userIsInRole([
                        Role.Employee,
                        Role.Monitor,
                        Role.OfficerSupervisor,
                        Role.Administrator,
                        Role.AgencyAdministrator,
                    ]),
                    path: url.includes('/administration/officer'),
                },
                {
                    label: t('menu.clients-management'),
                    onClick: () => {
                        navigate(CLIENT_MANAGEMENT);
                        dispatch(clearSearchValue());
                        dispatch(clearDeviceInfoSearchValue());
                    },
                    path: url.includes('/administration/client'),
                },
            ],
            path: adminHighlight||
             url.includes('/administration/client') ||
             url.includes('/administration/officer') || url.includes('/administration/agencies'),


        },
    ];

    return (
        <Stack
            direction="row"
            spacing={2}
            justifyContent={'flex-end'}
            flexGrow={1}
        >
            {options.map(({ label, onClick, options, visible, path }) => (
                <MenuOption
                    key={label}
                    label={label}
                    onClick={onClick}
                    options={options}
                    visible={visible}
                    path={path}
                />
            ))}
        </Stack>
    );
}

export default HeaderMenu;
