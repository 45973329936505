import { DataType } from 'src/models/dataType.enum';

export const xlsxImageDatatypes = [
    DataType.PHOTOS,
    DataType.SCREEN_CAPTURES,
    DataType.MMS,
    DataType.SMS,
    DataType.SCREENSHOTS,
];


export const columnsToKeyValue = (columns: string[]): string[] =>
{
    const keyValues: { [key: string]: string } = {
        'Flag': 'flag',
        'Captured Date': 'captured_date',
        'Received Date': 'received_date',
        'Device Alias': 'device',
        'Alert Word': 'alert_words',
        'Image': 'image',
        'Program': 'program',
        'Device Number': 'device_number',
        'External Number': 'external_number',
        'Sender': 'sms_type',
        'Message': 'message',
        'Contact Name': 'contact_name',
        'Data type': 'datatype',
        'Trigger': 'trigger',
        'Details': 'image',

    };
    return columns.map(column => keyValues[column] ?? '');
};
